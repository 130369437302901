import React from 'react'
import './ErrorPage.scss'

interface ErrorPageState {
  errorCode: number
  errorNotFoundMsg: string
  handleRetryClick: () => void
}

export const ErrorPage = (props: ErrorPageState) => {
  return (
    <div className="error-container">
      <h1>{props.errorCode === 404 ? props.errorNotFoundMsg : 'Oops! Something went wrong.'}</h1>
      {props.errorCode === 500 && (
        <auro-button class="error-button" onClick={props.handleRetryClick}>
          Try again
        </auro-button>
      )}
    </div>
  )
}
