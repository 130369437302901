import { AirportInformation } from '../airportInformation'
import { FlightInformation } from './flightInformation'
import { ProductInventory } from './productInventory'

export interface Flight {
  id: string
  partitionKey: string
  flightInformation: FlightInformation
  productInventories: { [key: string]: ProductInventory }
  categoryInventories: { [key: string]: CategoryInventory }
}

export interface FlightViewModel {
  id: string
  partitionKey: string
  flightInformation: FlightInformation
  productInventories: ProductInventory[]
  categoryInventories: CategoryInventory[]
}

export interface CategoryInventory {
  id: string
  name: string
  maxQuantity: number
  remainingQuantity: number
  sourceName: string
  lastModified: string
}

export interface FlightSearchResponse {
  id: string
  departureDate: string
  operatingFlightNumber: string
  departureAirportCode: string
  departureAirportInfo?: Partial<AirportInformation>
  arrivalAirportCode: string
  arrivalAirportInfo?: Partial<AirportInformation>
}
