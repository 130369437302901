import React, { useState } from 'react'
import './AdditionalFlightFields.scss'
import { SegmentedControl } from '../../../../common/SegmentedControl/SegmentedControl'
import { TimeframeOption } from '../../../../../models'

export const AdditionalFlightFields = () => {
  const [timeframeOption, setTimeframeOption] = useState(TimeframeOption.Between)

  const swapOriginDestinationInputs = () => {
    const departureInput = document.getElementById('departureAirportCode') as HTMLInputElement
    const arrivalInput = document.getElementById('arrivalAirportCode') as HTMLInputElement
    const departureInputValue = departureInput.value
    const arrivalInputValue = arrivalInput.value
    departureInput.value = arrivalInputValue
    arrivalInput.value = departureInputValue
  }

  const resetTimeframeOption = () => {
    const departureStartDate = document.getElementById('departureStartDate') as HTMLInputElement
    const departureEndDate = document.getElementById('departureEndDate') as HTMLInputElement
    departureStartDate.value = ''
    departureEndDate.value = ''
  }

  const renderAirportCodeInputFields = () => {
    return (
      <div className="input-field-rows">
        <auro-input
          id="departureAirportCode"
          bordered
          validateOnInput
          setCustomValidity="May only contain letters. Length of 3 characters"
          requestparam="departureAirportCode"
          label="Departure"
          pattern="(^$|[A-Za-z]{3})"
        >
          <span slot="helptext">Three letter airport code.</span>
        </auro-input>
        <auro-icon
          data-testid="swap-icon"
          className="icon-input-fields"
          category="terminal"
          name="round-trip-arrows"
          onClick={swapOriginDestinationInputs}
        />
        <auro-input
          id="arrivalAirportCode"
          bordered
          validateOnInput
          maxLength="3"
          setCustomValidity="May only contain letters. Length of 3 characters"
          requestparam="arrivalAirportCode"
          label="Arrival"
          pattern="(^$|[A-Za-z]{3})"
        >
          <span slot="helptext">Three letter airport code.</span>
        </auro-input>
      </div>
    )
  }

  return (
    <div className="additional-search-fields-container">
      <div>
        <auro-header display="400" margin="bottom" size="sm">
          Origin/destination
        </auro-header>
        {renderAirportCodeInputFields()}
      </div>
      <div>
        <auro-header display="400" margin="bottom" size="md">
          Timeframe
        </auro-header>
        <SegmentedControl
          options={Object.values(TimeframeOption)}
          selectedOption={timeframeOption}
          setSelectedOption={setTimeframeOption}
          handleOnClick={resetTimeframeOption}
        />
        <div className="input-field-rows">
          <auro-input
            id="departureStartDate"
            bordered
            validateOnInput
            requestparam="departureStartDate"
            label="Departure date"
            type="year-month-day"
            disabled={
              timeframeOption === TimeframeOption.Between || timeframeOption === TimeframeOption.After || timeframeOption === TimeframeOption.At
                ? undefined
                : true
            }
          />
          <span className={`timeframe-span${timeframeOption !== TimeframeOption.Between ? ' span-invisible' : ''}`}>to</span>
          <auro-input
            id="departureEndDate"
            bordered
            validateOnInput
            requestparam="departureEndDate"
            label="Departure date"
            type="year-month-day"
            disabled={timeframeOption === TimeframeOption.Between || timeframeOption === TimeframeOption.Before ? undefined : true}
          />
        </div>
      </div>
    </div>
  )
}
