import React from 'react'
import { BillingAddress, PaymentInformation } from '../../../../../models'
import { CreditCardIcon } from '../../../../common'
import './PaymentInfo.scss'

interface PaymentInfoProps {
  paymentInfo: PaymentInformation | null
}

export const PaymentInfo = (props: PaymentInfoProps) => {
  const cardOnFile = props.paymentInfo?.cardOnFilePayment ?? null
  const singleUseCard = props.paymentInfo?.singleUsePayment ?? null

  const renderCreditCardInfo = () => {
    return (
      <div className="credit-card-container">
        <span className="sub-label">Credit/debit card</span>
        <div>
          <span>{cardOnFile?.name ?? singleUseCard?.creditCard.nameOnCard}</span>
          <div className="credit-card-info">
            <CreditCardIcon cardType={cardOnFile?.creditCardType ?? singleUseCard?.creditCard.type ?? ''} />
            <span>{`****${cardOnFile?.creditCardLastFour ?? singleUseCard?.creditCard.lastFour}`}</span>
            {singleUseCard && (
              <span className="sub-label">{`exp ${singleUseCard?.creditCard.expiryMonth}/${singleUseCard?.creditCard.expiryYear}`}</span>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderBillingAddressInfo = (billingAddress: BillingAddress) => {
    return (
      <div className="billing-address-container">
        <span className="sub-label">Billing Address</span>
        <div>
          <span>{`${billingAddress.line1}`}</span>
          <br />
          {billingAddress.line2 && (
            <>
              <span>{billingAddress.line2}</span>
              <br />
            </>
          )}
          <span>{`${billingAddress.city}, ${billingAddress.stateProvince} ${billingAddress.postalCode}`}</span>
        </div>
      </div>
    )
  }

  return (
    (cardOnFile || singleUseCard) && (
      <div className="payment-method-container">
        <auro-header margin="both" size="none" level="1" display="300">
          Payment Method
        </auro-header>
        <div className="payment-info-container">
          {(cardOnFile || singleUseCard) && renderCreditCardInfo()}
          {singleUseCard && renderBillingAddressInfo(singleUseCard.billingAddress)}
        </div>
      </div>
    )
  )
}
