import React from 'react'
import './App.scss'
import {
  HomePage,
  SigninPage,
  Callback,
  AuthenticatedContainer,
  SearchPageContainer,
  ProductResults,
  ProductSearch,
  ProductDetails,
  OrderSearch,
  OrderDetails,
  OrderResults,
  GuestDetails,
  GuestSearch,
  GuestResults,
  FlightResults,
  FlightSearch,
  FlightDetails,
} from '..'
import { Routes, Route } from 'react-router-dom'

export const App = () => {
  return (
    <div className="main-container-CoolRunnings">
      <Routes>
        <Route path="/" element={<AuthenticatedContainer />}>
          <Route index element={<HomePage />} />
          <Route element={<SearchPageContainer />}>
            <Route path="products" element={<ProductSearch />}>
              <Route path="results" element={<ProductResults />} />
            </Route>
            <Route path="orders" element={<OrderSearch />}>
              <Route path="results" element={<OrderResults />} />
            </Route>
            <Route path="guests" element={<GuestSearch />}>
              <Route path="results" element={<GuestResults />} />
            </Route>
            <Route path="flights" element={<FlightSearch />}>
              <Route path="results" element={<FlightResults />} />
            </Route>
          </Route>
          <Route path="/products/results/:id" element={<ProductDetails />} />
          <Route path="/orders/results/:id/:partitionKey" element={<OrderDetails />} />
          {/* Guest with Mileage plan number */}
          <Route path="/guests/results/loyaltyNumber/:loyaltyNumber/name/:lastName/:firstName" element={<GuestDetails />} />
          <Route path="/guests/results/loyaltyNumber/:loyaltyNumber/name/:lastName/:firstName/order/:id/:partitionKey" element={<OrderDetails />} />
          {/* Guest without Mileage plan number */}
          <Route path="/guests/results/name/:lastName/:firstName/:id" element={<GuestDetails />} />
          <Route path="/guests/results/name/:lastName/:firstName/:id/order/:id/:partitionKey" element={<OrderDetails />} />
          <Route path="/flights/results/:flightId" element={<FlightDetails />} />
          <Route path="/flights/results/:flightId/product/:id/" element={<ProductDetails />} />
          <Route path="/flights/results/:flightId/order/:id/:partitionKey" element={<OrderDetails />} />
          <Route path="*" element={<auro-header>Sorry, page not found!</auro-header>} />
        </Route>
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/signin/callback" element={<Callback />} />
      </Routes>
    </div>
  )
}
