import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Guest } from '../../../models'

export interface GuestsState {
  results?: Guest[]
  selectedGuest?: Guest
}

export const guestsSlice = createSlice({
  name: 'guests',
  initialState: {
    results: undefined,
  } as GuestsState,
  reducers: {
    setGuests: (state, action: PayloadAction<{ results: Guest[] }>) => {
      state.results = action.payload.results
      return state
    },
    setSelectedGuest: (state, action: PayloadAction<{ selectedGuest: Guest }>) => {
      state.selectedGuest = action.payload.selectedGuest
      return state
    },
  },
})

export const { setGuests, setSelectedGuest } = guestsSlice.actions
export default guestsSlice.reducer
