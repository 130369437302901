import { CabinCode } from './cabinCode'

// In a partial flight case, these fields will be defined.
// operatingFlightNumber
// departureDate
// arrivalAirportCode
// departureAirportCode

export interface OrderFlightInformation {
  operatingFlightNumber: string
  marketingFlightNumber?: string
  operatingClassOfService?: string
  marketingClassOfService?: string
  departureDate: string
  departureDateTimeLocal: string
  departureAirportCode: string
  arrivalDate?: string
  arrivalDateTimeLocal?: string
  arrivalAirportCode: string
  cabin?: CabinCode
}
