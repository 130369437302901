import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RestError } from '../../../../models'
import { FormFieldMap, getSearchParams } from '../../../../utils'
import { ErrorPage } from '../..'
import { RootState, setLoadingState } from '../../../../store'
import { AdditionalSearchFieldsToggle, SearchFormButtons, useSearchContext } from '..'
import './SearchForm.scss'

export interface SearchFormProps {
  additionalFields: React.JSX.Element
  handleSearchButtonClick: (formValues: FormFieldMap) => Promise<void>
  handleClearFields: () => void
  handleInput?: () => void
  headerText: string
  mainFields: React.JSX.Element
  shouldDisableSearchButton?: boolean
}

export interface SearchFormState {
  statusCode: number
  showAdditionalFields: boolean
}

export const SearchForm = (props: SearchFormProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loadingState } = useSelector((rootState: RootState) => rootState)
  const { setHeader, setSubheader, showSearchForm } = useSearchContext()
  const [state, setState] = React.useState<SearchFormState>({ statusCode: 200, showAdditionalFields: false })

  useEffect(() => {
    document.querySelector('.search-form-container')?.addEventListener('auroMenu-selectedOption', () => {
      if (props.handleInput) {
        props.handleInput()
      }
    })
  }, [])

  const handleOnKeyDown = async (e: React.KeyboardEvent<HTMLFormElement>): Promise<void> => {
    if (e.key === 'Enter' && !props.shouldDisableSearchButton) {
      await handleSubmit()
    }
  }

  const handleSubmit = async () => {
    try {
      dispatch(setLoadingState({ isLoading: true }))
      const searchParams = getSearchParams()
      await props.handleSearchButtonClick(searchParams)
      dispatch(setLoadingState({ isLoading: false }))
      navigate('results')
    } catch (err) {
      dispatch(setLoadingState({ isLoading: false }))
      setState({ ...state, statusCode: (err as RestError).statusCode })
    }
  }

  const handleSetShowAdditionalFields = (showAdditionalFields: boolean) => {
    setState({ ...state, showAdditionalFields })
  }

  if (state.statusCode !== 200) {
    return (
      <ErrorPage
        errorCode={state.statusCode}
        errorNotFoundMsg={'No results found.'}
        handleRetryClick={() => {
          dispatch(setLoadingState({ isLoading: false }))
          setState({ ...state, statusCode: 200 })
        }}
      />
    )
  }

  return (
    <>
      <form
        onInput={props.handleInput}
        onKeyDown={handleOnKeyDown}
        className="search-form-container"
        style={{ display: showSearchForm ? 'flex' : 'none' }}
      >
        <auro-header class="search-form-main-header" display="400" margin="both" size="none">
          {props.headerText}
        </auro-header>
        <hr />
        <div className="search-form-content-container">
          {props.mainFields}
          <AdditionalSearchFieldsToggle setShowAdditionalFields={handleSetShowAdditionalFields} showAdditionalFields={state.showAdditionalFields} />
          {state.showAdditionalFields && props.additionalFields}
          <SearchFormButtons
            isLoading={loadingState.isLoading}
            handleClearFields={props.handleClearFields}
            handleSubmit={async () => await handleSubmit()}
            searchButtonIsDisabled={props.shouldDisableSearchButton}
          />
        </div>
      </form>
      <Outlet context={{ setHeader, setSubheader, showSearchForm }} />
    </>
  )
}
