import { RimProduct } from '../../rimProduct'

export const mockRimProducts = [
  {
    id: '50857c57-5a1c-426c-9360-c067783e7a0d',
    tenantCode: null,
    code: '40079',
    name: 'Eggs & Ham Breakfast Wrap',
    preOrderName: '  Eggs & Ham Breakfast Wrap',
    preOrderDescription:
      'Cage-free, hard boiled eggs dressed in a mustard herb dressing with crisp baby greens and fresh tomato, black forest ham, and caramelized onion cream cheese spread. Served with fresh grapes and apples',
    categoryId: '94bc64ce-12e4-454f-94fc-369fd3e9e829',
    externalPreOrderProductIdentifier: '40079',
    preOrderMarketing:
      'Cage-free, hard boiled eggs dressed in a mustard herb dressing with crisp baby greens and fresh tomato, black forest ham, and caramelized onion cream cheese spread. Served with fresh grapes and apples',
    preOrderIngredients: '500 calories, 32g fat, 63g carbohydrate, 23g protein, 376mg sodium, 17g sugar',
    preOrderAllergies: 'Egg, Wheat, Soy, Milk',
    hasLag: false,
    isPreOrderOutOfStock: false,
    stockCount: null,
    isPreOrderEnabled: true,
    isEnabled: true,
    hasImages: true,
    isNew: true,
    prices: [
      {
        id: '9360f7fb-a16f-4273-b14c-b0e047612c71',
        price: 8.5,
        currencyId: '67f1d404-7177-44b8-afe0-5b69a7e0e0e5',
        currencyCode: 'USD',
        priceTypeId: '0a6f6fa4-28fb-44ac-8adb-1294e00b6748',
      },
    ],
    deliveryAvailableHome: true,
    deliveryAvailableFlight: false,
    images: [
      {
        tenantCode: 'asa',
        key: '100x100-EggsHamBreakfastWrap_a7d9d32f-be75-48a3-a8e2-bd5b58606de3.jpg',
        productId: '50857c57-5a1c-426c-9360-c067783e7a0d',
        url: 'https://s3.us-west-2.amazonaws.com/asa-prod-usw2-storage-public/products/100x100-EggsHamBreakfastWrap_a7d9d32f-be75-48a3-a8e2-bd5b58606de3.jpg',
        description: '100x100-EggsHamBreakfastWrap.jpg',
        collection: 'products',
        fileName: '100x100-EggsHamBreakfastWrap.jpg',
        dateCreated: '2021-05-17T19:37:59.374Z',
        modifiedOn: '2021-05-17T19:37:59.374Z',
        modifiedBy: null,
        isPreOrder: true,
        isMainPreOrderImage: true,
      },
      {
        tenantCode: 'asa',
        key: '540x400-EggsHamBreakfastWrap_c7c77d01-0bff-4a33-a7f6-328fedf93958.jpg',
        productId: '50857c57-5a1c-426c-9360-c067783e7a0d',
        url: 'https://s3.us-west-2.amazonaws.com/asa-prod-usw2-storage-public/products/540x400-EggsHamBreakfastWrap_c7c77d01-0bff-4a33-a7f6-328fedf93958.jpg',
        description: '540x400-EggsHamBreakfastWrap.jpg',
        collection: 'products',
        fileName: '540x400-EggsHamBreakfastWrap.jpg',
        dateCreated: '2021-05-17T19:38:00.248Z',
        modifiedOn: '2021-05-17T19:38:00.248Z',
        modifiedBy: null,
        isPreOrder: true,
        isMainPreOrderImage: false,
      },
    ],
    translations: [],
    passengerClasses: [
      {
        code: 'Y',
      },
    ],
    activeFrom: '2021-04-05T00:00:00Z',
    activeTo: null,
    isHomeDelivery: false,
    isInflight: false,
    isViewOnly: false,
    availableCountries: [],
    airportsFrom: [],
    airportsTo: [],
    urlPreOrderName: '--eggs--ham-breakfast-wrap',
    urlId: '50857c',
    timeRules: {
      inflight: {
        createOrderStart: {
          days: 15,
          hours: 0,
          minutes: 0,
        },
        updateOrderEnd: {
          days: 0,
          hours: 20,
          minutes: 0,
        },
        cancelOrderEnd: {
          days: 0,
          hours: 20,
          minutes: 0,
        },
      },
    },
    vatRateId: null,
  },
  {
    id: 'bb8d06b2-f3e1-4030-b186-eacdbb831d2d',
    tenantCode: 'asa',
    code: '20919',
    name: 'Afternoon Protein Platter',
    preOrderName: 'Afternoon Protein Platter',
    preOrderDescription:
      "Lemon Beet hummus, fresh pita, Beecher's Flagship Cheese, non-GMO fire-braised turkey, almonds, a cage-free hardboiled egg and plump cherry tomatoes, fresh sliced carrots and cucumbers",
    categoryId: '8f8f92a1-f9e5-4e6b-93a1-7066279d86b8',
    externalPreOrderProductIdentifier: '20919',
    preOrderMarketing:
      "Lemon Beet hummus, fresh pita, Beecher's Flagship Cheese, non-GMO fire-braised turkey, almonds, a cage-free hardboiled egg and plump cherry tomatoes, fresh sliced carrots and cucumbers",
    preOrderIngredients: null,
    preOrderAllergies: null,
    hasLag: false,
    isPreOrderOutOfStock: false,
    stockCount: null,
    isPreOrderEnabled: true,
    isEnabled: true,
    hasImages: false,
    isNew: true,
    prices: [
      {
        id: '546e65f7-a08d-400e-bda7-f6c44c69b9dc',
        price: 0,
        currencyId: '67f1d404-7177-44b8-afe0-5b69a7e0e0e5',
        currencyCode: 'USD',
        priceTypeId: '0a6f6fa4-28fb-44ac-8adb-1294e00b6748',
      },
    ],
    deliveryAvailableHome: true,
    deliveryAvailableFlight: false,
    images: [],
    translations: [],
    passengerClasses: [
      {
        code: 'J',
      },
    ],
    activeFrom: '2021-05-01T00:00:00Z',
    activeTo: null,
    isHomeDelivery: false,
    isInflight: false,
    isViewOnly: false,
    availableCountries: [],
    airportsFrom: [],
    airportsTo: [],
    urlPreOrderName: 'afternoon-protein-platter',
    urlId: 'bb8d06',
    timeRules: {
      inflight: {
        createOrderStart: {
          days: 15,
          hours: 0,
          minutes: 0,
        },
        updateOrderEnd: {
          days: 0,
          hours: 19,
          minutes: 0,
        },
        cancelOrderEnd: {
          days: 0,
          hours: 19,
          minutes: 0,
        },
      },
    },
    vatRateId: null,
  },
] as unknown as RimProduct[]
