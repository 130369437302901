import React from 'react'
import './AdditionalOrderFields.scss'

export const AdditionalOrderFields = () => {
  const orderStatuses = ['Cancelled', 'Placed']

  const renderFormFieldsDivider = (auroIcon: React.JSX.IntrinsicElements['auro-icon'], dividerText: string) => {
    return (
      <div className="divider-container">
        {auroIcon}
        <auro-header margin="both" size="none" display="400">
          {dividerText}
        </auro-header>
        <hr className="horizontal-divider" />
      </div>
    )
  }

  return (
    <div className="additional-search-fields-container">
      <div className="form-field-container">
        {renderFormFieldsDivider(<auro-icon customSize category="terminal" name="plane-up-stroke" />, 'Flight information')}
        <div className="input-field-rows">
          <auro-input
            bordered
            validateOnInput
            maxLength="6"
            setCustomValidity="May only contain letters. Length of 6 characters."
            requestparam="confirmationCode"
            label="Confirmation code"
            pattern="(^$|[A-Za-z]{6})"
          >
            <span slot="helptext">May only contain letters. Length of 6 characters.</span>
          </auro-input>
        </div>
        <div className="input-field-rows">
          <auro-input bordered validateOnInput type="number" maxLength="4" requestparam="flightNumber">
            <slot slot="label">Flight number</slot>
            <span slot="helptext">May only contain numbers.</span>
          </auro-input>
          <auro-input bordered validateOnInput requestparam="departureDate" label="Date" type="year-month-day" />
          <auro-input
            bordered
            validateOnInput
            setCustomValidity="May only contain letters. Length of 3 characters"
            requestparam="departureAirportCode"
            label="Departure"
            pattern="(^$|[A-Za-z]{3})"
          >
            <span slot="helptext">Three letter airport code.</span>
          </auro-input>
          <auro-input
            bordered
            validateOnInput
            maxLength="3"
            setCustomValidity="May only contain letters. Length of 3 characters"
            requestparam="arrivalAirportCode"
            label="Arrival"
            pattern="(^$|[A-Za-z]{3})"
          >
            <span slot="helptext">Three letter airport code.</span>
          </auro-input>
        </div>
      </div>
      <div className="form-field-container">
        {renderFormFieldsDivider(<auro-icon customSize category="interface" name="account-stroke" />, 'Guest information')}
        <div className="input-field-rows">
          <auro-input
            bordered
            validateOnInput
            maxLength="26"
            setCustomValidity="May only contain letters"
            requestparam="firstName"
            label="First name"
            pattern="(^$|[A-Za-z -]{1,26})"
          />
          <auro-input
            bordered
            validateOnInput
            maxLength="26"
            setCustomValidity="May only contain letters"
            requestparam="lastName"
            label="Last name"
            pattern="(^$|[A-Za-z -]{1,26})"
          />
          <auro-input bordered validateOnInput maxLength="50" requestparam="loyaltyNumber" label="Mileage Plan number" />
          <auro-input bordered validateOnInput type="email" requestparam="email" label="Email" />
        </div>
      </div>
      <div className="form-field-container">
        {renderFormFieldsDivider(<auro-icon customSize category="in-flight" name="food-and-drink" />, 'Order information')}
        <div className="input-field-rows">
          <auro-select requestparam="status" placeholder="Order status">
            <auro-menu requestparam="orderStatusMenu">
              {orderStatuses.map((t) => (
                <auro-menuoption key={t} value={t}>
                  {t}
                </auro-menuoption>
              ))}
            </auro-menu>
          </auro-select>
          <auro-input bordered validateOnInput type="number" requestparam="numberOfProductsOrdered" label="Items ordered">
            <span slot="helptext">May only contain numbers.</span>
          </auro-input>
          <auro-input bordered validateOnInput type="number" requestparam="total" label="Order total">
            <span slot="helptext">May only contain numbers.</span>
          </auro-input>
        </div>
      </div>
    </div>
  )
}
