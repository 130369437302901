import { CabinCode, OrderSearchResponse, OrderType } from '../order'

export const mockOrders: OrderSearchResponse[] = [
  {
    confirmationCode: 'EHQMBT',
    cabinCode: CabinCode.MainCabin,
    displayCabin: 'Main Cabin (Y)',
    operatingClassOfService: 'Y',
    departureDate: '2022-10-22',
    id: '4-DCA-SEA-2022-10-22-EHQMBT-3sDfNZrV',
    operatingFlightNumber: '4',
    partitionKey: '4-DCA-SEA-2022-10-22-EHQMBT',
    status: 'Placed',
    type: OrderType.Preorder,
  },
  {
    confirmationCode: 'ABCDEF',
    cabinCode: CabinCode.MainCabin,
    displayCabin: 'Main Cabin (J)',
    operatingClassOfService: 'J',
    departureDate: '2022-10-22',
    id: '4-DCA-SEA-2022-10-22-ABCDEF-3sDfNZrV',
    operatingFlightNumber: '4',
    partitionKey: '4-DCA-SEA-2022-10-22-ABCDEF',
    status: 'Delivered',
    type: OrderType.Preorder,
  },
  {
    confirmationCode: 'FEDCBA',
    cabinCode: CabinCode.MainCabin,
    displayCabin: 'Main Cabin (J)',
    operatingClassOfService: 'J',
    departureDate: '2022-10-22',
    id: '4-DCA-SEA-2022-10-22-FEDCBA-3sDfNZrV',
    operatingFlightNumber: '4',
    partitionKey: '4-DCA-SEA-2022-10-22-FEDCBA',
    status: 'Cancelled',
    type: OrderType.Preorder,
  },
]
