import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridCsvExportMenuItem,
  GridRowIdGetter,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FlightSearchResponse, Guest, OrderSearchResponse, Product } from '../../../models'
import { RootState } from '../../../store'
import { useSearchContext } from '../Search'
import { ProductInventoryView } from '../../pages'
import './ResultsTable.scss'

export interface ResultsTableProps {
  columns: GridColDef[]
  items: Product[] | OrderSearchResponse[] | Guest[] | FlightSearchResponse[] | ProductInventoryView[]
  getRowId?: GridRowIdGetter
  handleRowClick: (params: GridRowParams) => void
  columnVisibilityModel?: GridColumnVisibilityModel
  hideHeaderText?: boolean
  exportFilename?: string
}

export const ResultsTable = (props: ResultsTableProps) => {
  const { loadingState } = useSelector((state: RootState) => state)
  const { setHeader, setSubheader } = useSearchContext()
  const { columns, handleRowClick, items } = props

  useEffect(() => {
    if (!props.hideHeaderText) {
      const countResults = items.length
      if (countResults > 0) {
        setHeader(`${countResults} matches found.`)
      } else {
        setHeader('No matches found.')
        setSubheader('Try another search')
      }
    }
  })

  const customToolbar = () => {
    const now = new Date().toISOString()
    const filename = `${props.exportFilename ?? 'results'}-${now}`
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem options={{ fileName: filename }} />
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    )
  }

  return (
    items && (
      <DataGrid
        sx={{
          fontFamily: 'inherit',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 300,
            fontSize: 'medium',
          },
        }}
        slots={{ toolbar: customToolbar }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
          columns: {
            columnVisibilityModel: props.columnVisibilityModel,
          },
        }}
        columns={columns}
        rows={items}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        getRowId={props.getRowId}
        onRowClick={handleRowClick}
        rowBuffer={25}
        rowHeight={72}
        loading={loadingState.isLoading}
      />
    )
  )
}
