import { FlightSearchResponse } from '..'

export const mockFlights: FlightSearchResponse[] = [
  {
    id: '4-DCA-SEA-2022-10-22',
    departureDate: '2022-10-22',
    operatingFlightNumber: '4',
    departureAirportCode: 'DCA',
    departureAirportInfo: { itmName: 'Wash DC-Reagan', region: 'DC', code: 'DCA' },
    arrivalAirportCode: 'SEA',
    arrivalAirportInfo: { itmName: 'Seattle', region: 'WA', code: 'SEA' },
  },
  {
    id: '1-SEA-DCA-2022-10-22',
    departureDate: '2022-10-22',
    operatingFlightNumber: '1',
    departureAirportCode: 'SEA',
    departureAirportInfo: { itmName: 'Seattle', region: 'WA', code: 'SEA' },
    arrivalAirportCode: 'DCA',
    arrivalAirportInfo: { itmName: 'Wash DC-Reagan', region: 'DC', code: 'DCA' },
  },
]
