import React from 'react'
import { useNavigate } from 'react-router-dom'
import { authService } from '../../../services'
import './NavBar.scss'

export const NavBar = (props: { userName: string }) => {
  const navigate = useNavigate()

  const signOut = () => {
    authService.signOut()
  }

  const navigateToHomePage = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <>
      <nav className="nav-bar">
        <auro-lockup onClick={navigateToHomePage}>
          <span slot="title">ASTRO</span>
        </auro-lockup>
        <div className="right-nav-bar-container">
          <ul className="nav-categories">
            <li className="nav-products">
              <auro-hyperlink onClick={() => navigate('/products')}>Products</auro-hyperlink>
            </li>
            <li className="nav-orders">
             <auro-hyperlink onClick={() => navigate('/orders')}>Orders</auro-hyperlink>
            </li>
            <li className="nav-guests">
              <auro-hyperlink onClick={() => navigate('/guests')}>Guests</auro-hyperlink>
            </li>
            <li className="nav-flights">
              <auro-hyperlink onClick={() => navigate('/flights')}>Flights</auro-hyperlink>
            </li>
          </ul>
          <div className="nav-menu">
            <auro-dropdown id="commonSlot" rounded chevron>
              <span slot="trigger">Hi, {props.userName}</span>
              <div className="nav-menu-dropdown">
                <auro-hyperlink
                  type="nav"
                  target="_blank"
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=1MVED7BCwkW_VdD-qEMNM29yZPQfCjtGkWFHzXZwzdhUQjU2RDFCQlo3QlRJTENIQ05aOEhJQjBRMS4u"
                >
                  Give feedback
                </auro-hyperlink>
                <auro-hyperlink
                  type="nav"
                  target="_blank"
                  href="https://alaskaair.sharepoint.com/:p:/s/Serve-Inflight/Ed-FNxTj-iNFrUmvoG7frqoBgeKrdVll9pS30pANmrxQqw?e=DaSwgt"
                >
                  Get help
                </auro-hyperlink>
                <hr />
                <auro-hyperlink data-testid="sign-out-btn" target="_blank" onClick={signOut}>
                  Sign out
                </auro-hyperlink>
              </div>
            </auro-dropdown>
          </div>
        </div>
      </nav>
      <div className="horizontal-divider" />
    </>
  )
}
