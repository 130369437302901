import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { authService } from '../../../services'
import { RootState, SigninState } from '../../../store'

export const Callback = () => {
  const { accessToken } = useSelector<RootState, SigninState>((state) => state.signin)

  useEffect(() => {
    const fetchTokens = async () => {
      await authService.fetchServiceConfig()
      await authService.completeAuthorizationRequestIfPossible()
    }
    fetchTokens()
  }, [])

  return accessToken ? <Navigate to="/" /> : <></>
}
