import { Product } from '../product'

export const mockProducts: Product[] = [
  {
    allergyInfo: 'Egg, Wheat, Soy, Milk',
    cabinCode: 'Y',
    category: 'Meals',
    code: '40079',
    description:
      'Cage-free, hard boiled eggs dressed in a mustard herb dressing with crisp baby greens and fresh tomato, black forest ham, and caramelized onion cream cheese spread. Served with fresh grapes and apples',
    id: '50857c57-5a1c-426c-9360-c067783e7a0d',
    images: [
      'https://coolrunnings.test.alaskaair.com/blob/product-images/100x100-EggsHamBreakfastWrap_a7d9d32f-be75-48a3-a8e2-bd5b58606de3.jpg',
      'https://coolrunnings.test.alaskaair.com/blob/product-images/540x400-EggsHamBreakfastWrap_c7c77d01-0bff-4a33-a7f6-328fedf93958.jpg',
    ],
    isActive: true,
    name: 'Eggs & Ham Breakfast Wrap',
    nutritionInfo: '500 calories, 32g fat, 63g carbohydrate, 23g protein, 376mg sodium, 17g sugar',
    partitionKey: 'products',
    prices: [
      { price: 8.5, startDate: '2019-05-19T15:11:29.831Z', endDate: '2020-05-19T15:11:29.831Z' },
      { price: 9.5, startDate: '2023-05-19T15:11:29.831Z', endDate: '2040-05-19T15:11:29.831Z' },
    ],
    _ts: 1663868319,
    startTimeOffset: undefined,
    endTimeOffset: undefined,
    cancelEndTimeOffset: undefined,
  },
  {
    allergyInfo: '',
    cabinCode: 'J',
    category: 'FC Meals',
    code: '20919',
    description:
      "Lemon Beet hummus, fresh pita, Beecher's Flagship Cheese, non-GMO fire-braised turkey, almonds, a cage-free hardboiled egg and plump cherry tomatoes, fresh sliced carrots and cucumbers",
    id: 'bb8d06b2-f3e1-4030-b186-eacdbb831d2d',
    images: [],
    isActive: true,
    name: 'Afternoon Protein Platter',
    nutritionInfo: '',
    partitionKey: 'products',
    prices: [
      { price: 0, startDate: '2019-05-19T15:11:29.831Z', endDate: '2020-05-19T15:11:29.831Z' },
      { price: 8.5, startDate: '2021-05-19T15:11:29.831Z', endDate: '2022-05-19T15:11:29.831Z' },
      { price: 9.5 },
    ],
    _ts: 1663868319,
    startTimeOffset: undefined,
    endTimeOffset: undefined,
    cancelEndTimeOffset: undefined,
  },
]
