import { Flight } from '..'

export const mockFlight: Flight = {
  id: '1-DCA-SEA-2023-10-28',
  partitionKey: '1-DCA-SEA-2023-10-28',
  flightInformation: {
    operatingFlightNumber: '1',
    marketingFlightNumber: '1',
    departureDate: '2023-10-28',
    departureDateTimeLocal: '2023-10-28T08:00:00-04:00',
    departureAirportCode: 'DCA',
    arrivalAirportCode: 'SEA',
    arrivalAirportInfo: {
      itmName: 'Seattle',
      region: 'WA',
      code: 'SEA',
    },
    departureAirportInfo: {
      itmName: 'Wash DC-Reagan',
      region: 'DC',
      code: 'DCA',
    },
  },
  productInventories: {
    '18000': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 16,
      remainingQuantity: 16,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '21088': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 16,
      remainingQuantity: 16,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '21089': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 4,
      remainingQuantity: 4,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '21090': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 16,
      remainingQuantity: 16,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '21118': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 4,
      remainingQuantity: 4,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '40013': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 77,
      remainingQuantity: 77,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '41015': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 77,
      remainingQuantity: 77,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '41017': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 77,
      remainingQuantity: 77,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    '41019': {
      categoryId: '1',
      categoryName: 'Entrees',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 77,
      remainingQuantity: 77,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    pkids: {
      categoryId: '2',
      categoryName: 'Snacks',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 4,
      remainingQuantity: 4,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    pdeli: {
      categoryId: '2',
      categoryName: 'Snacks',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 4,
      remainingQuantity: 4,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
    ptapas: {
      categoryId: '2',
      categoryName: 'Snacks',
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      maxQuantity: 4,
      remainingQuantity: 4,
      isOfferedPreflight: true,
      isOfferedInflight: true,
      preorderStartTime: '2023-10-13T12:00:00Z',
      preorderStartTimeLocal: '2023-10-13T08:00:00-04:00',
      preorderStartTimeOffset: -21600,
      preorderEndTime: '2023-10-28T11:00:00Z',
      preorderEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderEndTimeOffset: -60,
      preorderCancelEndTime: '2023-10-28T11:00:00Z',
      preorderCancelEndTimeLocal: '2023-10-28T07:00:00-04:00',
      preorderCancelEndTimeOffset: -60,
    },
  },
  categoryInventories: {
    '1': {
      id: '1',
      name: 'Entrees',
      maxQuantity: 77,
      remainingQuantity: 77,
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      lastModified: '2021-10-13T12:00:00Z',
    },
    '2': {
      id: '2',
      name: 'Snacks',
      maxQuantity: 4,
      remainingQuantity: 4,
      sourceName: 'P_POSched_AS_20231024_000000.csv',
      lastModified: '2021-10-13T12:00:00Z',
    },
  },
}
