import React from 'react'
import './SearchFormButtons.scss'

export const SearchFormButtons = (props: {
  isLoading: boolean
  handleSubmit: () => void
  handleClearFields: () => void
  searchButtonIsDisabled?: boolean
}) => {
  const { isLoading, handleClearFields, handleSubmit, searchButtonIsDisabled } = props

  return (
    <div className="search-button-container slide">
      <auro-button
        class="clear-fields-button"
        tertiary
        data-testid="clear-button"
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
          if (e.key === 'Enter') {
            handleClearFields()
            e.stopPropagation()
          }
        }}
        onClick={handleClearFields}
      >
        Clear search fields
      </auro-button>
      <auro-button
        class="search-button"
        loading={isLoading ? true : undefined}
        disabled={searchButtonIsDisabled ? true : undefined}
        data-testid="search-button"
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
          if (e.key === 'Enter') {
            handleSubmit()
            e.stopPropagation()
          }
        }}
        onClick={handleSubmit}
      >
        Search
      </auro-button>
    </div>
  )
}
