import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { flightService } from '../../../../services'
import { ErrorPage, InfoBox, TabSelector } from '../../../common'
import { useFetchState } from '../../../../hooks'
import { setBreadcrumbs } from '../../../../store'
import { toDateString, toAirportString, getFlightDetailsBreadCrumbs, toTimeString } from '../../../../utils'
import { CategoryInventory, Flight, FlightViewModel, ProductInventory } from '../../../../models'
import { FlightSkeleton } from './FlightSkeleton/FlightSkeleton'
import { FlightProductsTable } from './FlightProductsTable/FlightProductsTable'
import { FlightOrdersTable } from './FlightOrdersTable/FlightOrdersTable'
import './FlightDetails.scss'

export const FlightDetails = () => {
  const dispatch = useDispatch()
  const { flightId } = useParams()
  const fetchData = async () => await flightService.getFlight(flightId as string)
  const { fetchState, retryFetchData } = useFetchState<FlightViewModel>(fetchData)
  const [selectedTab, setSelectedTab] = useState('Inventory')
  const [inventoryTab, setInventoryTab] = useState('Inventory')
  const [ordersTab, setOrdersTab] = useState('Orders')

  useEffect(() => {
    dispatch(setBreadcrumbs(getFlightDetailsBreadCrumbs(flightId as string)))
  })

  const renderTable = (tabOption: string, table: ReactElement) => {
    const hideTable = selectedTab.startsWith(tabOption) ? '' : ' hidden'
    return <div className={'bottom-content-container table-container' + hideTable}>{table}</div>
  }

  if (fetchState.errorCode) {
    return <ErrorPage errorCode={fetchState.errorCode} errorNotFoundMsg={'Flight not found.'} handleRetryClick={retryFetchData} />
  }

  if (!fetchState.data) {
    return <FlightSkeleton />
  }

  return (
    <div className="flight-details-container">
      <div className="top">
        <auro-avatar tail="AS" />
        <div className="header-container">
          <auro-header margin="both" size="none" level="1" display="600">
            {`Alaska Airlines ${fetchState.data.flightInformation.operatingFlightNumber}`}
          </auro-header>
          <span className="h2">{`Departing ${toDateString(fetchState.data.flightInformation.departureDate)}`}</span>
          <div className="info-box-container">
            <InfoBox
              header="Departure"
              info={toAirportString(fetchState.data.flightInformation.departureAirportInfo!)}
              footer={toTimeString(fetchState.data.flightInformation.departureDateTimeLocal)}
            />
            <InfoBox header="Arrival" info={toAirportString(fetchState.data.flightInformation.arrivalAirportInfo!)} />
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="bottom-title-container">
          {<TabSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} options={[inventoryTab, ordersTab]} />}
        </div>
        <div className="category-info-box-container">
          {(fetchState.data.categoryInventories as CategoryInventory[]).map((category: CategoryInventory) => {
            return <InfoBox header={`Max ${category.name.toLowerCase()}`} info={`${category.remainingQuantity}/${category.maxQuantity}`} />
          })}
        </div>
        {renderTable(
          'Orders',
          <FlightOrdersTable
            exportFilename={`AS${fetchState.data.flightInformation.operatingFlightNumber}-${fetchState.data.flightInformation.departureDate}-orders`}
            flightInfo={fetchState.data.flightInformation}
            setTabOption={setOrdersTab}
          />,
        )}
        {renderTable(
          'Inventory',
          <FlightProductsTable
            exportFilename={`AS${fetchState.data.flightInformation.operatingFlightNumber}-${fetchState.data.flightInformation.departureDate}-products`}
            productInventories={fetchState.data.productInventories as ProductInventory[]}
            setTabOption={setInventoryTab}
          />,
        )}
      </div>
    </div>
  )
}
