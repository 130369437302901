import { AccountInformation } from '..'

export interface Passenger {
  sequenceId: string
  lastName: string
  firstName: string
  accountInformation: AccountInformation
  seatNumber: string | null
  items: Item[]
}

export interface Item {
  isDelivered: boolean | null
  id: string
  code: string
  name: string
  imageThumbnail: string
  originalPrice: number
  actualPrice: number
  discountText?: string
  discountType?: string
  voucherReason?: string
  voucherReference?: VoucherReference
}

export interface VoucherReference {
  id: string
  partitionKey: string
}
