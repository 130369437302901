import React from 'react'
import './OrderSkeleton.scss'

export const OrderSkeleton = () => {
  return (
    <div className="order-skeleton">
      <div className="header-skeleton">
        <auro-skeleton class="title" shape="rectangle"></auro-skeleton>
        <auro-skeleton class="sub-title" shape="rectangle"></auro-skeleton>
      </div>
      <div className="content-skeleton">
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <div>
          <auro-skeleton class="tabs-skeleton" shape="rectangle"></auro-skeleton>
          <auro-skeleton class="tabs-details-skeleton" shape="rectangle"></auro-skeleton>
        </div>
      </div>
    </div>
  )
}
