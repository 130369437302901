import React from 'react'
import { Passenger } from '../../../../../models'
import { InfoBox } from '../../../../common'
import './GuestInfo.scss'

interface GuestInfoProps {
  passengers: Passenger[]
}

export const GuestInfo = (props: GuestInfoProps) => {
  return (
    <div>
      {props.passengers.map((pax, index) => {
        const loyaltyInfo = pax.accountInformation?.loyaltyInformation
        return (
          <div className="guest-info-container" key={pax.sequenceId}>
            <auro-header margin="both" size="none" level="5" display="400">
              {pax.firstName ?? pax.accountInformation?.firstName} {pax.lastName ?? pax.accountInformation?.lastName}
            </auro-header>
            {loyaltyInfo?.loyaltyNumber && <span>{`Mileage Plan number: ${loyaltyInfo.loyaltyAirlineCode} ${loyaltyInfo.loyaltyNumber}`}</span>}
            {pax.accountInformation?.loyaltyNumber && (
              <span>{`Mileage Plan number: ${pax.accountInformation.loyaltyAirlineCode} ${pax.accountInformation.loyaltyNumber}`}</span>
            )}
            {pax.accountInformation?.email && <span>{pax.accountInformation.email}</span>}
            <InfoBox iconName="seat" iconCategory="in-flight" header="Seat" info={pax.seatNumber?.toString() ?? 'N/A'} />
            {index !== props.passengers.length - 1 && <hr />}
          </div>
        )
      })}
    </div>
  )
}
