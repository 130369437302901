import React from 'react'
import './CreditCardIcon.scss'

interface CreditCardIconProps {
  cardType: string
}

export function CreditCardIcon(props: CreditCardIconProps) {
  switch (props.cardType?.toUpperCase()) {
    case 'BAAS':
      return <auro-icon customSize category="payment" name="cc-alaska" />
    case 'AS':
      return <auro-icon customSize category="payment" name="cc-alaska" />
    case 'BA':
      return <auro-icon customSize category="payment" name="cc-visa" />
    case 'IK':
      return <auro-icon customSize category="payment" name="cc-mastercard" />
    case 'AX':
      return <auro-icon customSize category="payment" name="cc-amex" />
    case 'DS':
      return <auro-icon customSize category="payment" name="cc-discover" />
    default:
      return <auro-icon customSize category="payment" name="credit-card" />
  }
}
