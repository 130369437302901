import React from 'react'
import './AdditionalSearchFieldsToggle.scss'

export const AdditionalSearchFieldsToggle = (props: {
  showAdditionalFields: boolean
  setShowAdditionalFields: (showAdditionalFields: boolean) => void
}) => {
  const { showAdditionalFields, setShowAdditionalFields } = props

  return (
    <div className="additional-fields-button-container">
      {showAdditionalFields && <auro-icon category="interface" accent name="chevron-down" customSize />}
      {!showAdditionalFields && <auro-icon category="interface" accent name="plus-stroke" customSize />}
      <button
        onClick={(e) => {
          e.preventDefault()
          setShowAdditionalFields(!showAdditionalFields)
          e.stopPropagation()
        }}
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault()
            setShowAdditionalFields(!showAdditionalFields)
          }
        }}
      >
        Show additional fields
      </button>
    </div>
  )
}
