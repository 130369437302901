import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { guestService } from '../../../../services'
import { setBreadcrumbs, setGuests } from '../../../../store'
import { clearFields, FormFieldMap, getSearchCrumbs, DomainTypes, setButtonState } from '../../../../utils'
import { SearchForm } from '../../../common'

export const GuestSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isDisabled, setDisabled] = React.useState(true)

  useEffect(() => {
    if (!location.pathname.includes('results')) {
      dispatch(setBreadcrumbs(getSearchCrumbs(DomainTypes.GUESTS)))
    }
  }, [location.pathname])

  const handleClearFields = () => {
    clearFields()
  }

  const handleSubmit = async (searchParams: FormFieldMap) => {
    const response = await guestService.search(searchParams)
    dispatch(setGuests({ results: response }))
  }

  const renderMainInputFields = () => {
    return (
      <div className="main-input-fields">
        <auro-input
          bordered
          validateOnInput
          maxLength="26"
          setCustomValidity="May only contain letters"
          requestparam="firstName"
          label="First name"
          pattern="(^$|[A-Za-z -]{1,26})"
        />
        <auro-input
          bordered
          validateOnInput
          maxLength="26"
          setCustomValidity="May only contain letters"
          requestparam="lastName"
          label="Last name"
          pattern="(^$|[A-Za-z -]{1,26})"
        />
        <auro-input
          bordered
          validateOnInput
          maxLength="6"
          setCustomValidity="May only contain letters. Length of 6 characters."
          requestparam="confirmationCode"
          label="Confirmation code"
          pattern="(^$|[A-Za-z]{6})"
        />
        <auro-input bordered validateOnInput maxLength="50" requestparam="loyaltyNumber" label="Mileage Plan number" />
      </div>
    )
  }

  const renderAdditionalInputFields = () => {
    return (
      <div className="additional-search-fields-container">
        <div className="input-field-rows">
          <auro-input
            bordered
            validateOnInput
            setCustomValidity="Please enter a valid email address (name@domain.com)."
            requestparam="email"
            label="Email"
            pattern="(^$|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b)"
          >
            <span slot="helptext">May only contain letters. Length of 6 characters.</span>
          </auro-input>
          <auro-input
            bordered
            validateOnInput
            maxLength="26"
            setCustomValidity="May only contain letters"
            requestparam="middleName"
            label="Middle name"
            pattern="(^$|[A-Za-z -]{1,26})"
          />
          <auro-input
            bordered
            validateOnInput
            maxLength="3"
            setCustomValidity="May only contain letters and numbers."
            requestparam="seatNumber"
            label="Seat number"
            pattern="(^$|([1-9]|[1-9][0-9])[A-Za-z]{1})"
          />
          <auro-input
            bordered
            validateOnInput
            maxLength="26"
            setCustomValidity="May only contain letters"
            requestparam="preferredName"
            label="Preferred name"
            pattern="(^$|[A-Za-z -]{1,26})"
          />
        </div>
      </div>
    )
  }

  return (
    <SearchForm
      headerText="Guest search"
      mainFields={renderMainInputFields()}
      additionalFields={renderAdditionalInputFields()}
      handleClearFields={handleClearFields}
      handleSearchButtonClick={handleSubmit}
      handleInput={setButtonState.bind(this, setDisabled)}
      shouldDisableSearchButton={isDisabled}
    />
  )
}
