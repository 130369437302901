import { TimeframeOption } from '../models'
import { productService } from '../services'

export type FormFieldMap = {
  [formField: string]: string | number | boolean
}

export const getSearchParams = () => {
  const inputFields = [...document.querySelectorAll('auro-input, auro-select')] as HTMLInputElement[]

  return inputFields.reduce((params, currentInputField) => {
    params[(currentInputField as any).attributes.requestparam.value] = currentInputField.value
    return params
  }, {} as FormFieldMap)
}

export const populateCategories = async (setCategories: (categories: string[]) => void) => {
  let categories: string[]

  const sessionStorageCategories = sessionStorage.getItem('categories')
  if (!sessionStorageCategories) {
    categories = await productService.getCategories()
    sessionStorage.setItem('categories', JSON.stringify(categories))
  } else {
    categories = sessionStorageCategories.length === 0 ? [] : JSON.parse(sessionStorageCategories)
  }
  setCategories(categories)
}

export const clearFields = (): void => {
  const inputElements = [...document.querySelectorAll('auro-input, auro-select')] as unknown as { value: string }[]
  inputElements.forEach((el) => {
    el.value = ''
  })
}

export const getValidAuroInputs = (): HTMLInputElement[] => {
  const auroInputs = [...document.querySelectorAll('auro-input')] as HTMLInputElement[]
  return auroInputs.filter((el: any) => {
    return el.value && el.validity === 'valid'
  })
}

export const getNonEmptyAuroSelects = (): HTMLInputElement[] => {
  const auroSelect = [...document.querySelectorAll('auro-select')] as HTMLInputElement[]
  return auroSelect.filter((el: HTMLInputElement) => el.value)
}

export const getInvalidAuroInputs = (): HTMLInputElement[] => {
  const auroInputs = [...document.querySelectorAll('auro-input')] as HTMLInputElement[]

  return auroInputs.filter((el: any) => {
    return el.value && el.validity !== 'valid'
  })
}

export const normalizeAtTimeframe = (searchParams: FormFieldMap) => {
  if (searchParams.departureStartDate) {
    const timeframe = document.querySelector('.selected')
    if (timeframe?.textContent === TimeframeOption.At) {
      searchParams.departureEndDate = searchParams.departureStartDate
    }
  }
  return searchParams
}
