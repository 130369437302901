import { Breadcrumb } from '../store'

export enum DomainTypes {
  FLIGHTS = 'flights',
  GUESTS = 'guests',
  HOME = 'Home',
  ORDERS = 'orders',
  PRODUCTS = 'products',
}

const DomainMap = {
  [DomainTypes.HOME]: { url: '/', displayName: 'Home' },
  [DomainTypes.FLIGHTS]: { url: '/flights', displayName: 'Flights' },
  [DomainTypes.GUESTS]: { url: '/guests', displayName: 'Guests' },
  [DomainTypes.ORDERS]: { url: '/orders', displayName: 'Orders' },
  [DomainTypes.PRODUCTS]: { url: '/products', displayName: 'Products' },
}

const initialBreadCrumbs = (domain: DomainTypes) => [
  { url: `${DomainMap[DomainTypes.HOME].url}`, displayName: DomainMap[DomainTypes.HOME].displayName },
  { url: `${DomainMap[domain].url}`, displayName: DomainMap[domain].displayName },
]

export const getSearchCrumbs = (domain: DomainTypes) => {
  return initialBreadCrumbs(domain)
}

export const getResultsCrumbs = (domain: DomainTypes) => {
  return [...getSearchCrumbs(domain), { url: `${DomainMap[domain].url}/results`, displayName: 'Results' }]
}

export const getDetailsCrumbs = (domain: DomainTypes, item: { id: string; displayName: string }) => {
  return [...getResultsCrumbs(domain), { url: `/${domain}/results/${item.id}`, displayName: item.displayName }]
}

export const getGuestDetailsBreadCrumbs = (
  loyaltyNumber: string | undefined,
  lastName: string,
  firstName: string,
  orderId: string | undefined,
): Breadcrumb[] => {
  const fullName = firstName + ' ' + lastName
  if (loyaltyNumber) {
    return [
      ...getResultsCrumbs(DomainTypes.GUESTS),
      { url: `/guests/results/loyaltyNumber/${loyaltyNumber}/name/${lastName}/${firstName}`, displayName: fullName },
    ]
  } else {
    return [...getResultsCrumbs(DomainTypes.GUESTS), { url: `/guests/results/name/${lastName}/${firstName}/${orderId}`, displayName: fullName }]
  }
}

export const getGuestOrderBreadCrumbs = (
  mileagePlanNumber: string | undefined,
  lastName: string,
  firstName: string,
  id: string,
  partitionKey: string,
): Breadcrumb[] => {
  const crumbs = [...getGuestDetailsBreadCrumbs(mileagePlanNumber, lastName, firstName, id)]
  crumbs.push({ url: crumbs[crumbs.length - 1].url + `/order/${id}/${partitionKey}`, displayName: id })
  return crumbs
}

export const getFlightDetailsBreadCrumbs = (flightId: string) => {
  const flightDisplayName = `AS ${flightId.split('-')[0]}`
  const crumbs = [...getDetailsCrumbs(DomainTypes.FLIGHTS, { id: flightId, displayName: flightDisplayName })]
  return crumbs
}

export const getFlightProductBreadCrumbs = (flightId: string, productId: string, productDisplayName: string): Breadcrumb[] => {
  const crumbs = getFlightDetailsBreadCrumbs(flightId)
  crumbs.push({ url: crumbs[crumbs.length - 1].url + `/product/${productId}`, displayName: productDisplayName })
  return crumbs
}

export const getFlightOrderBreadCrumbs = (flightId: string, id: string, partitionKey: string): Breadcrumb[] => {
  const crumbs = getFlightDetailsBreadCrumbs(flightId)
  crumbs.push({ url: crumbs[crumbs.length - 1].url + `/order/${id}/${partitionKey}`, displayName: id })
  return crumbs
}
