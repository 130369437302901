import React from 'react'
import './ProductSkeleton.scss'

export const ProductSkeleton = () => {
  return (
    <div className="productSkeleton">
      <div className="headerSkeleton">
        <auro-skeleton class="image" shape="circle"></auro-skeleton>
        <div className="headerInfoSkeleton">
          <auro-skeleton shape="rectangle"></auro-skeleton>
          <auro-skeleton shape="rectangle"></auro-skeleton>
          <auro-skeleton shape="rectangle"></auro-skeleton>
          <auro-skeleton shape="rectangle"></auro-skeleton>
        </div>
      </div>
      <div className="infoSkeleton">
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
      </div>
    </div>
  )
}
