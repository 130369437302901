import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const loadingStateSlice = createSlice({
  name: 'loadingState',
  initialState: { isLoading: false},
  reducers: {
    setLoadingState: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading
      return state
    },
  },
})

export const { setLoadingState } = loadingStateSlice.actions
export default loadingStateSlice.reducer
