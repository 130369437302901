import { combineReducers } from 'redux'
import breadcrumbsReducer from './features/breadcrumbs/breadcrumbsSlice'
import flightsReducer from './features/flights/flightsSlice'
import guestsReducer from './features/guests/guestsSlice'
import loadingReducer from './features/loadingState/loadingStateSlice'
import orderReducer from './features/orders/ordersSlice'
import productReducer from './features/products/productsSlice'
import signinReducer from './features/signin/signinSlice'

const rootReducer = combineReducers({
  breadcrumbs: breadcrumbsReducer,
  flights: flightsReducer,
  guests: guestsReducer,
  loadingState: loadingReducer,
  orders: orderReducer,
  products: productReducer,
  signin: signinReducer,
})

export default rootReducer
