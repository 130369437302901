import React, { useEffect } from 'react'
import { ActivityLog } from '../../../../models'
import { productService } from '../../../../services'
import './ProductSync.scss'
import { useLocation } from 'react-router-dom'

export enum SyncErrorType {
  GetError,
  SyncError,
}

export interface ProductSyncState {
  isSyncing: boolean
  lastActivityLog: ActivityLog | null
  hasError: boolean
  errorType: SyncErrorType | null
}

export const ProductSync = (props: { userName: string }) => {
  const location = useLocation()
  const [state, setState] = React.useState({ isSyncing: false, hasError: false, errorType: null, lastActivityLog: null } as ProductSyncState)

  useEffect(() => {
    const getLastSyncJob = async () => {
      try {
        const lastSyncJob = await productService.getLastSyncJob()
        setState({ ...state, lastActivityLog: lastSyncJob })
      } catch (err) {
        console.error(err)
        setState({ isSyncing: false, hasError: true, errorType: SyncErrorType.GetError, lastActivityLog: null })
      }
    }

    if (location.pathname === '/products/results') {
      getLastSyncJob()
    }
  }, [location.pathname])

  const handleSyncClick = async () => {
    setState({ ...state, isSyncing: true })
    try {
      const lastActivityLog = await productService.syncWithRim(props.userName)
      setState({ isSyncing: false, hasError: false, errorType: null, lastActivityLog: lastActivityLog })
    } catch (err) {
      console.error(err)
      setState({ ...state, isSyncing: false, hasError: true, errorType: SyncErrorType.SyncError })
    }
  }

  const handleExportClick = () => {
    alert('Exporting search results is not yet implemented.')
  }

  const getLastSyncedDateTimeMessage = (timestamp: string): string => {
    const lastSyncedDateTime = new Date(timestamp)
    const time = lastSyncedDateTime.toLocaleTimeString([], { timeStyle: 'short' })
    const date = lastSyncedDateTime.toLocaleDateString()
    return `Last synced on ${date} at ${time}`
  }

  const renderLastSyncedDateTime = () => {
    if (state.hasError) {
      if (state.errorType === SyncErrorType.GetError) {
        return <p className="error">Cannot verify time of last sync.</p>
      } else {
        let message = 'Cannot sync data.'
        if (state.lastActivityLog) {
          message += ' ' + getLastSyncedDateTimeMessage(state.lastActivityLog.timestamp)
        }
        return <p className="error">{message}</p>
      }
    }

    if (state.lastActivityLog) {
      return <p>{getLastSyncedDateTimeMessage(state.lastActivityLog.timestamp)}</p>
    }

    return null
  }

  return location.pathname === '/products/results' ? (
    <div className="sync-container">
      <div className="text-and-loader">
        {state.isSyncing && <auro-loader onlight={true} size="xxs" orbit={true} />}
        {!state.isSyncing && renderLastSyncedDateTime()}
      </div>
      <div className="divider" />
      <auro-dropdown class="dropdown" rounded toggle>
        <auro-icon slot="trigger" name="navigation-menu" category="interface" />
        <div>
          <auro-button tertiary onclick="document.querySelector('.dropdown').hide()" onClick={handleExportClick}>
            <auro-icon name="penguin" category="interface" />
            Export
          </auro-button>
        </div>
        <div>
          <auro-button
            disabled={state.isSyncing ? true : undefined}
            class="sync-button"
            tertiary
            onclick="document.querySelector('.dropdown').hide()"
            onClick={handleSyncClick}
          >
            <auro-icon name="flight-changes" category="terminal" />
            Sync
          </auro-button>
        </div>
      </auro-dropdown>
    </div>
  ) : null
}
