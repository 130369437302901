import React from 'react'
import './FlightSkeleton.scss'

export const FlightSkeleton = () => {
  return (
    <div className="flight-skeleton">
      <div className="header-skeleton">
        <auro-skeleton class="image" shape="circle"></auro-skeleton>
        <div className="header-info-skeleton">
          <auro-skeleton shape="rectangle"></auro-skeleton>
          <auro-skeleton shape="rectangle"></auro-skeleton>
          <div className="info-box-skeleton-container">
            <auro-skeleton shape="rectangle"></auro-skeleton>
            <auro-skeleton shape="rectangle"></auro-skeleton>
          </div>
        </div>
      </div>
      <div className="info-skeleton">
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
        <auro-skeleton shape="rectangle"></auro-skeleton>
      </div>
    </div>
  )
}
