import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'
import './SearchPageContainer.scss'

type SearchContext = {
  setHeader: (header: string) => void
  setSubheader: (subheader: string) => void
  showSearchForm: boolean
}

const BASE_URLS = ['/products', '/orders', '/guests', '/flights']

export const SearchPageContainer = () => {
  const pathUrl = useLocation()
  const [showSearchForm, setShowSearchForm] = useState(true)
  const [header, setHeader] = useState('')
  const [subheader, setSubheader] = useState('')

  useEffect(() => {
    if (BASE_URLS.includes(pathUrl.pathname)) {
      setShowSearchForm(true)
      setHeader(pathUrl.pathname.charAt(1).toUpperCase() + pathUrl.pathname.slice(2))
      setSubheader('Add more search fields to narrow down your results.')
    } else {
      setShowSearchForm(false)
    }
  }, [pathUrl])

  return (
    <section className="search-page-container">
      <div className="search-page-header">
        <auro-header display="700" margin="both" size="none">
          {header}
        </auro-header>
        <div className="search-page-subheader">
          <p>{subheader}</p>
          {pathUrl.pathname.split('/')[2] === 'results' && pathUrl.pathname.split('/').length < 4 && (
            <div className="search-icons-container">
              <auro-icon category="interface" name="search" accent onClick={setShowSearchForm.bind(this, !showSearchForm)}></auro-icon>
            </div>
          )}
        </div>
      </div>
      <Outlet context={{ setHeader, setSubheader, showSearchForm }} />
    </section>
  )
}

export const useSearchContext = () => {
  return useOutletContext<SearchContext>()
}
