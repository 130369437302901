export const getAuthorizedGroups = (): string[] => {
  const groups: string[] = []
  if (process.env.ASTROREADONLY_GROUP) {
    groups.push(process.env.ASTROREADONLY_GROUP)
  }
  if (process.env.ASTROREADANDWRITE_GROUP) {
    groups.push(process.env.ASTROREADANDWRITE_GROUP)
  }
  return groups
}

export const isUserAuthorized = (userGroups: string[], authorizedGroups: string[]): boolean => {
  return userGroups.some((g) => authorizedGroups.includes(g))
}
