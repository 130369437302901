import React, { useEffect } from 'react'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState, setBreadcrumbs } from '../../../../store'
import { OrderStatusPill, ResultsTable } from '../../../common'
import { DomainTypes, getResultsCrumbs, toDateString } from '../../../../utils'
import './OrderResults.scss'

export const orderResultsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Order number',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 130,
    renderCell: (params) => <OrderStatusPill status={params.value} />,
  },
  { field: 'type', minWidth: 130, headerName: 'Order type' },
  { field: 'operatingFlightNumber', minWidth: 150, headerName: 'Flight number' },
  {
    field: 'displayCabin',
    minWidth: 150,
    headerName: 'Cabin',
  },
  {
    field: 'departureDate',
    headerName: 'Departure date',
    minWidth: 150,
    renderCell: (params) => {
      return toDateString(params.value)
    },
  },
  { field: 'confirmationCode', headerName: 'Confirmation code', minWidth: 200 },
  {
    field: 'passengers',
    headerName: 'Guests',
    minWidth: 250,
    valueFormatter: (params) => {
      return formatPassenger(params.value)
    },
    renderCell: (params) => renderPassengerCell(params.value),
  },
]

export const OrderResults = () => {
  const { orders } = useSelector((state: RootState) => state)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!orders.results) {
      navigate('/orders')
    }
    dispatch(setBreadcrumbs(getResultsCrumbs(DomainTypes.ORDERS)))
  }, [])

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/orders/results/${params.id}/${params.row.partitionKey}`, { state: `Order #${orders.results!.find((p) => p.id === params.id)!.id}` })
  }

  return orders.results ? (
    <div className="resultsContainer">
      <ResultsTable exportFilename="order-search-results" columns={orderResultsColumns} handleRowClick={handleRowClick} items={orders.results} />
    </div>
  ) : (
    <></>
  )
}

export const renderPassengerCell = (passengers: { firstName: string; lastName: string }[]): React.ReactElement | string => {
  const paxCount = passengers.length
  if (paxCount === 0) {
    return <></>
  }
  if (paxCount === 1) {
    return `${passengers[0].firstName} ${passengers[0].lastName}`
  }
  return (
    <auro-popover>
      <span slot="trigger">
        {passengers[0].firstName} {passengers[0].lastName} + {passengers.length - 1} more
      </span>
      <ul>
        {passengers.map((p: { firstName: string; lastName: string }, index) => (
          <li key={`${p.firstName}-${p.lastName}-${index}`}>
            {p.firstName} {p.lastName}
          </li>
        ))}
      </ul>
    </auro-popover>
  )
}

export const formatPassenger = (passengers: { firstName: string; lastName: string }[]): string => {
  const paxNames = passengers.map((x) => {
    return x.firstName + ' ' + x.lastName
  })
  return paxNames.join(',')
}
