import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Guest } from '../../../../models'
import { RootState, setBreadcrumbs, setSelectedGuest } from '../../../../store'
import { getResultsCrumbs, DomainTypes } from '../../../../utils'
import { ResultsTable } from '../../../common'

export const guestResultsColumns: GridColDef[] = [
  {
    field: 'fullName',
    flex: 1,
    headerName: 'Name',
  },
  {
    field: 'loyaltyNumber',
    flex: 1,
    headerName: 'Mileage Plan number',
    renderCell: (params) => renderLoyaltyInfoCell(params),
  },
  {
    field: 'lastCreatedAt',
    flex: 1,
    headerName: 'Last placed date',
    renderCell: (params) => toLocalDate(params),
  },
  { field: 'numberOfOrders', flex: 1, headerName: 'Number of orders' },
]

const toLocalDate = (params: any) => {
  const date = new Date(params.row.lastCreatedAt)
  return date.toLocaleString()
}

const renderLoyaltyInfoCell = (params: { row: { loyaltyNumber?: string; loyaltyAirlineCode?: string } }) => {
  if (!params.row.loyaltyAirlineCode && !params.row.loyaltyNumber) {
    return null
  }
  return `${params.row.loyaltyAirlineCode} ${params.row.loyaltyNumber}`
}

export const GuestResults = () => {
  const dispatch = useDispatch()
  const { guests } = useSelector((state: RootState) => state)
  const navigate = useNavigate()

  useEffect(() => {
    if (!guests.results) {
      navigate('/guests')
    }
    dispatch(setBreadcrumbs(getResultsCrumbs(DomainTypes.GUESTS)))
  }, [])

  const handleRowClick = (params: GridRowParams) => {
    dispatch(setSelectedGuest({ selectedGuest: params.row as Guest }))
    if (params.row.loyaltyNumber) {
      navigate(`/guests/results/loyaltyNumber/${params.row.loyaltyNumber}/name/${params.row.lastName}/${params.row.firstName}`)
    } else {
      navigate(`/guests/results/name/${params.row.lastName}/${params.row.firstName}/${params.row.orderId}`)
    }
  }

  return guests.results ? (
    <div className="resultsContainer">
      <ResultsTable exportFilename="guest-search-results" columns={guestResultsColumns} handleRowClick={handleRowClick} items={guests.results} />
    </div>
  ) : (
    <></>
  )
}
