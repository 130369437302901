export interface Guest {
  fullName: string
  firstName: string
  lastName: string
  loyaltyNumber?: string
  numberOfOrders: number
  orderId: string
  partitionKey: string
  lastCreatedAt: string
}
