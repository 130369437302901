import { GridColDef, GridRenderCellParams, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState, setBreadcrumbs } from '../../../../store'
import { getResultsCrumbs, toDollarUSFormat, DomainTypes } from '../../../../utils'
import { ActiveStateIcon, ResultsTable } from '../../../common'
import './ProductResults.scss'

export const productResultsColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1.5,
    renderCell: (params) => renderProductImageAndName(params),
  },
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'code', headerName: 'Code', flex: 0.5 },
  { field: 'category', headerName: 'Category', flex: 0.5 },
  {
    field: 'price',
    flex: 0.5,
    headerAlign: 'right',
    align: 'right',
    headerName: 'Price',
    valueFormatter: (params: GridValueFormatterParams<number>) => {
      return toDollarUSFormat(params.value)
    },
  },
  {
    field: 'isActive',
    headerName: 'Active',
    renderCell: (params) => <ActiveStateIcon isActive={params.value} />,
  },
]

export const ProductResults = () => {
  const { products } = useSelector((state: RootState) => state)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!products.results) {
      navigate('/products')
    }
    dispatch(setBreadcrumbs(getResultsCrumbs(DomainTypes.PRODUCTS)))
  }, [])

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/products/results/${params.id}`, { state: products.results!.find((p) => p.id === params.id)!.name })
  }

  return products.results ? (
    <div className="resultsContainer productResultsContainer">
      <ResultsTable
        exportFilename="product-search-results"
        columns={productResultsColumns}
        handleRowClick={handleRowClick}
        items={products.results}
      />
    </div>
  ) : (
    <></>
  )
}

export const renderProductImageAndName = (params: GridRenderCellParams) => {
  const size = params.row.images.length
  const hasImages = params.row.images && size > 0
  return (
    <div className="productNameAndImage">
      <div className="imageContainer">
        {hasImages ? <img alt={params.row.name} src={params.row.images[size - 1]} /> : <auro-icon customSize category="in-flight" name="food-and-drink" />}
      </div>
      <span>{params.row.name}</span>
    </div>
  )
}
