import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Breadcrumb {
  url: string
  displayName: string
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: [{ url: '/', displayName: 'Home' }],
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      state = action.payload //NOSONAR
      return state
    },
  },
})

export const { setBreadcrumbs } = breadcrumbsSlice.actions
export default breadcrumbsSlice.reducer
