import { createSlice } from '@reduxjs/toolkit'

export interface SigninState {
  accessToken?: string
}

export const signinSlice = createSlice({
  name: 'signin',
  initialState: {} as SigninState,
  reducers: {
    setAccessToken: (state, action) => {
      // this event is used by Callback.tsx to redirect to App Home Page when a token is received
      state.accessToken = action.payload
      return state
    },
  },
})

export const { setAccessToken } = signinSlice.actions
export default signinSlice.reducer
