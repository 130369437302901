import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FlightSearchResponse } from '../../../models'

export interface FlightsState {
  results?: FlightSearchResponse[]
}

export const flightsSlice = createSlice({
  name: 'flights',
  initialState: {
    results: undefined,
  } as FlightsState,
  reducers: {
    setFlights: (state, action: PayloadAction<{ results: FlightSearchResponse[] }>) => {
      state.results = action.payload.results
      return state
    },
  },
})

export const { setFlights } = flightsSlice.actions
export default flightsSlice.reducer
