import React from 'react'

interface ActiveStateIconProps {
  isActive: boolean
}

export const ActiveStateIcon = (props: ActiveStateIconProps) => (
  <auro-icon
    class={props.isActive ? 'active' : 'inactive'}
    customColor={true}
    category="interface"
    name={`${props.isActive ? 'check' : 'minus'}-filled`}
  />
)
