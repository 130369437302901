import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { authService } from '../../../services'
import { getAuthorizedGroups, isUserAuthorized } from '../../../utils'
import { ProductSync, NotAuthorized } from '../../pages'
import { appInsights } from '../../../appInsights'
import { Breadcrumbs, NavBar } from '..'
import './AuthenticatedContainer.scss'

interface AuthenticatedContainerState {
  userName?: string
  groups?: string[]
}

export const AuthenticatedContainer = () => {
  const [state, setState] = useState<AuthenticatedContainerState>({})
  const location = useLocation()

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        await authService.fetchServiceConfig()
        const userInfo = await authService.fetchUserInfo()
        setState({ userName: userInfo.given_name, groups: userInfo.groups })
        appInsights.trackEvent(
          { name: 'User signed in' },
          { title: userInfo.title, divisionName: userInfo.division_name, departmentName: userInfo.department_name },
        )
      } catch (err) {
        authService.signOut()
      }
    }
    fetchUserInfo()
  }, [])

  if (state.userName === undefined || state.groups === undefined) {
    return <></>
  }

  if (!isUserAuthorized(state.groups, getAuthorizedGroups())) {
    return <NotAuthorized />
  }

  return (
    <>
      <NavBar userName={state.userName} />
      <div className="sub-nav-container">
        <Breadcrumbs />
        <ProductSync userName={state.userName} />
      </div>
      {location.pathname !== '/' && <hr />}
      <div className="app-content-container">
        <Outlet context={state.userName} />
      </div>
    </>
  )
}
