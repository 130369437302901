import React, { useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setBreadcrumbs } from '../../../store'
import {} from '../../../utils'
import './Home.scss'

export const HomePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userName = useOutletContext()

  useEffect(() => {
    dispatch(setBreadcrumbs([{ displayName: 'Home', url: '/' }]))
  }, [])

  return (
    <section className="home-page-container">
      <auro-header display="800" margin="both" size="none">
        Welcome back, {userName}.
      </auro-header>
      <ul className="images-list-container">
        <li className="products-panel" >
          <button type='button' onClick={() => navigate('/products')}><h1>Products</h1></button>
        </li>
        <li className="orders-panel">
          <button type='button' onClick={() => navigate('/orders')}><h1>Orders</h1></button>
        </li>
        <li className="guests-panel" >
          <button type='button' onClick={() => navigate('/guests')}><h1>Guests</h1></button>
        </li>
        <li className="flights-panel">
        <button type='button' onClick={() => navigate('/flights')}><h1>Flights</h1></button>
        </li>
      </ul>
    </section>
  )
}
