export interface ProductInventory {
  code?: string
  categoryId: string
  categoryName: string
  sourceName: string
  maxQuantity: number
  remainingQuantity: number
  isOfferedPreflight: boolean
  isOfferedInflight: boolean
  preorderStartTimeOffset: number // should be negative
  preorderStartTime: string
  preorderStartTimeLocal: string
  preorderEndTimeOffset: number // should be negative
  preorderEndTime: string
  preorderEndTimeLocal: string
  preorderCancelEndTimeOffset: number // should be negative
  preorderCancelEndTime: string
  preorderCancelEndTimeLocal: string
}
