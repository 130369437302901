import { AccountInformation } from '../models'

export const getFullName = (createdBy?: AccountInformation): string => {
  if (!createdBy) {
    return 'Guest'
  }

  if (!createdBy.firstName && !createdBy.lastName) {
    return 'Guest'
  }

  let fullName = (createdBy.firstName ?? '') + ' ' + (createdBy.lastName ?? '')
  const formattedParts: string[] = []
  const nameParts = fullName.match(/\w+/g)
  nameParts!.forEach((part) => {
    formattedParts.push(part.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()))
  })

  return formattedParts.join(' ')
}
