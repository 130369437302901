import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { RootState } from '../../../store'
import './Breadcrumbs.scss'

export const Breadcrumbs = () => {
  const { breadcrumbs } = useSelector((state: RootState) => state)
  const navigate = useNavigate()

  const onBackButtonClick = () => {
    navigate(breadcrumbs[breadcrumbs.length - 2].url)
  }

  return (
    <section className="breadcrumbs-container">
      {breadcrumbs.length > 1 && (
        <auro-button slim variant="secondary" aria-label="arrow-left" onClick={onBackButtonClick}>
          <div className="back-button-container">
            <auro-icon customsize customcolor category="interface" name="arrow-left" slot="icon"></auro-icon>
            Back
          </div>
        </auro-button>
      )}
      <div className="breadcrumbs-content">
        {breadcrumbs.map((crumb, index) => {
          if (index !== breadcrumbs.length - 1) {
            return (
              <div key={crumb.url}>
                <Link to={crumb.url}>{crumb.displayName}</Link>
                <span className="slash">/</span>
              </div>
            )
          } else {
            return <p key={crumb.url}>{crumb.displayName}</p>
          }
        })}
      </div>
    </section>
  )
}
