import React from 'react'
import { OrderFlightInformation } from '../../../../../models'
import { toDateString, toTimeString } from '../../../../../utils'
import './FlightInfo.scss'

interface FlightInfoProps {
  flightInfo: OrderFlightInformation
}

export const FlightInfo = (props: FlightInfoProps) => {
  const hasPartialFlightInfo = (flightInfo: OrderFlightInformation) => {
    return !(
      flightInfo &&
      flightInfo.arrivalAirportCode &&
      flightInfo.arrivalDate &&
      flightInfo.departureAirportCode &&
      flightInfo.departureDate &&
      flightInfo.marketingFlightNumber
    )
  }
  const hasPartialData = hasPartialFlightInfo(props.flightInfo)

  const renderFlightLockup = (header: string, info: string) => {
    return (
      <div className="flight-lockup">
        <auro-icon name="plane-side-stroke" category="terminal" customSize />
        <div>
          <auro-header margin="both" size="none" level="5" display="400">
            {header}
          </auro-header>
          <span>{info}</span>
        </div>
      </div>
    )
  }

  const renderFlight = (flightInfo: OrderFlightInformation) => {
    return (
      <div className="flight-summary">
        <div>
          <span className="sub-label">Departure</span>
          <span className="airport-label">{flightInfo.departureAirportCode}</span>
          <span className="date-label">
            {toDateString(flightInfo.departureDate)} {flightInfo.departureDateTimeLocal && toTimeString(flightInfo.departureDateTimeLocal)}
          </span>
        </div>
        {flightInfo.arrivalDate && (
          <>
            <auro-flightline></auro-flightline>
            <div>
              <span className="sub-label">Arrival</span>
              <span className="airport-label">{flightInfo.arrivalAirportCode}</span>
              <span className="date-label">
                {toDateString(flightInfo.arrivalDate)} {flightInfo.arrivalDateTimeLocal && toTimeString(flightInfo.arrivalDateTimeLocal)}
              </span>
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <div className="flight-info-container">
      {renderFlightLockup(
        !hasPartialData ? `${props.flightInfo.marketingFlightNumber}` : 'N/A',
        `${props.flightInfo.departureAirportCode}-${props.flightInfo.arrivalAirportCode}`,
      )}
      {(!hasPartialData && renderFlight(props.flightInfo)) ||
        renderFlightLockup('Departure Date', `${new Date(props.flightInfo.departureDate).toDateString()}`)}
    </div>
  )
}
