import React from 'react'
import './NotAuthorized.scss'

export const NotAuthorized = () => {
  return (
    <div className="notAuthorizedContainer">
      <auro-lockup>
        <span slot="title">ASTRO</span>
        <span slot="subtitle">Alaska Transactions and Orders Management System</span>
      </auro-lockup>
      <div className="content">
        <auro-header>It looks like this account is not authorized to access ASTRO.</auro-header>
        <p className="message">
          Please request to be added to ASTROReadOnly group by emailing{' '}
          <a target="_blank" href="mailto:its.service.desk@alaskaair.com?subject=Please add me to ASTROReadonly group">
            its.service.desk@alaskaair.com
          </a>
        </p>
      </div>
    </div>
  )
}
