import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'
import { App } from './components'
import { appInsights } from './appInsights'
import './webComponents'
import './site.scss'

appInsights.loadAppInsights()

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)
