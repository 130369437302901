import { OrderFlightInformation } from './OrderFlightInformation/OrderFlightInformation'
import { CabinCode } from './OrderFlightInformation/cabinCode'
import { OrderStatusInformation } from './orderStatusInformation/orderStatusInformation'
import { OrderSummary } from './orderSummary/orderSummary'
import { PartialDataInformation } from './partialDataInformation/partialDataInformation'

export interface Order {
  id: string
  partitionKey: string
  partialDataInformation: PartialDataInformation
  cabinCode: CabinCode
  confirmationCode: string
  type: OrderType
  flightInformation: OrderFlightInformation
  currentStatus: OrderStatusInformation
  statusHistory: OrderStatusInformation[]
  currentOrder: OrderSummary
  orderHistory: OrderSummary[]
}

export interface OrderSearchResponse {
  id: string
  cabinCode: CabinCode
  confirmationCode: string
  departureDate: string
  operatingFlightNumber: string
  operatingClassOfService: string
  partitionKey: string
  status: string
  type: OrderType
  displayCabin: string
}

export interface OrderConstantsResponse {
  orderTypes: string[]
  preorderOrderStatuses: string[]
  onboardOrderStatuses: string[]
}

export enum OrderType {
  Preorder = 'Preorder',
  Onboard = 'Onboard',
}
