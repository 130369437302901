export interface SingleUsePayment {
  creditCard: CreditCard
  billingAddress: BillingAddress
}

export interface CardOnFilePayment {
  id: string
  memberGuid: string
  name: string
  creditCardLastFour: string
  creditCardType: string
}

export interface CreditCard {
  token: Token
  brandName: string
  type: string
  firstSix: string // Is first 6 required?
  lastFour: string
  expiryMonth: number
  expiryYear: number
  nameOnCard: string
}

export interface Token {
  id: string
  tokenizedCardNumber: string
  tokenizedExpirationDate: string
}

export interface BillingAddress {
  line1: string
  line2: string
  city: string
  stateProvince: string
  postalCode: string
  countryCode: string
}
