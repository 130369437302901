import { authService } from '..'
import { CabinCode, OrderSearchResponse, RestError } from '../../models'
import { Order, OrderType } from '../../models/order/order'

export interface OrderSearchParams {
  id?: string
  flightNumber?: string
  departureDate?: string
  departureCity?: string
  arrivalCity?: string
  confirmationCode?: string
  firstName?: string
  lastName?: string
  loyaltyNumber?: string
  email?: string
  orderType?: string
  orderStatus?: string
  itemsOrdered?: string
  total?: string
}

class OrderService {
  private BASE_URL = process.env.API_BASEURL
  private PATH = process.env.API_PATH
  private KEY = process.env.API_KEY ?? ''

  async search(searchParams: OrderSearchParams): Promise<OrderSearchResponse[]> {
    searchParams.orderType = OrderType.Preorder // Remove once onboard is on the roadmap
    const response = await fetch(`${this.BASE_URL}${this.PATH}/orders/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
      body: JSON.stringify(searchParams),
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }

    const orders = await response.json()

    orders.forEach((order: OrderSearchResponse) => {
      order.displayCabin =
        order.cabinCode === CabinCode.MainCabin ? `Main cabin (${order.operatingClassOfService})` : `First class (${order.operatingClassOfService})`
    })

    return orders
  }

  async getOrder(id: string, partitionKey: string): Promise<Order> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/orders/${id}/${partitionKey}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return response.json()
  }
}

export const orderService = new OrderService()
