import { ActivityLog, Product } from '../../models'
import { RestError } from '../../models/restError'
import { authService } from '../auth/authService'

export interface ProductSearchParams {
  cabin?: string
  category?: string
  minPrice?: number
  maxPrice?: number
  price?: string
  productId?: string
  productName?: string
  isActive?: boolean
}

class ProductService {
  private BASE_URL = process.env.API_BASEURL
  private PATH = process.env.API_PATH
  private KEY = process.env.API_KEY ?? ''

  async search(searchParams: ProductSearchParams): Promise<Product[]> {
    const normalizedSearchParams = searchParams.price ? this.extractPriceRange(searchParams) : searchParams

    const response = await fetch(`${this.BASE_URL}${this.PATH}/products/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
      body: JSON.stringify(normalizedSearchParams),
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return response.json()
  }

  async searchByProductCodes(codes: string[]): Promise<Product[]> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/products/search/codes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
      body: JSON.stringify({ codes }),
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return await response.json()
  }

  async getProduct(id: string): Promise<Product> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/products/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return (await response.json()) as Product
  }

  async getCategories(): Promise<string[]> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/products/categories`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return (await response.json()) as string[]
  }

  async syncWithRim(userName: string): Promise<ActivityLog> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/products/sync?&createdBy=${userName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return response.json()
  }

  async getLastSyncJob(): Promise<ActivityLog> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/products/sync/logs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    const firstActivity = ((await response.json()) as ActivityLog[]).shift() as ActivityLog
    if (!firstActivity) {
      throw new Error('No sync jobs found.')
    }

    return firstActivity
  }

  private extractPriceRange(searchParams: ProductSearchParams) {
    const [minPrice, maxPrice] = searchParams.price!.split('-')
    delete searchParams.price
    searchParams['minPrice'] = parseFloat(minPrice)
    if (maxPrice) {
      searchParams['maxPrice'] = parseFloat(maxPrice)
    }
    return searchParams
  }
}

export const productService = new ProductService()
