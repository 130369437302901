import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Product } from '../../../models'

export interface ProductsState {
  results?: Product[]
}

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    results: undefined,
  } as ProductsState,
  reducers: {
    setProducts: (state, action: PayloadAction<{ results: Product[] }>) => {
      state.results = action.payload.results
      return state
    },
  },
})

export const { setProducts } = productsSlice.actions
export default productsSlice.reducer
