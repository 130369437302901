import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderSearchResponse } from '../../../models'

export interface OrdersState {
  results?: OrderSearchResponse[]
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    results: undefined,
  } as OrdersState,
  reducers: {
    setOrders: (state, action: PayloadAction<{ results: OrderSearchResponse[] }>) => {
      state.results = action.payload.results
      return state
    },
  },
})

export const { setOrders } = ordersSlice.actions
export default ordersSlice.reducer
