import { useEffect, useState } from 'react'
import { RestError } from '../models'
interface UseFetchDataState<T> {
  data?: T
  errorCode?: number
}

/**
 * Fetches and returns data and the response status code.
 */
export function useFetchState<Type>(fetchData: () => Promise<Type>) {
  const [fetchState, setFetchState] = useState<UseFetchDataState<Type>>({})

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      setFetchState({ ...fetchState, errorCode: undefined })
      const data = await fetchData()
      setFetchState({ ...fetchState, data: data })
    } catch (err) {
      setFetchState({ ...fetchState, errorCode: (err as RestError).statusCode ?? 500 })
    }
  }

  return { fetchState, setFetchState, retryFetchData: getData }
}
