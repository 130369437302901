import React from 'react'
import './InfoBox.scss'

interface BoxedInfoProps {
  iconName?: string
  iconCategory?: string
  header: string
  info: string
  footer?: string | null
}

export function InfoBox(props: BoxedInfoProps) {
  return (
    <div className="box-info-container">
      {props.iconName && props.iconCategory && <auro-icon customSize name={props.iconName} category={props.iconCategory} />}
      <div className="right-box-container">
        <span>{props.header}</span>
        <span>{props.info}</span>
        {props.footer && <span>{props.footer}</span>}
      </div>
    </div>
  )
}
