export interface UserInfo {
  department_name: string
  division_name: string
  domain_username: string
  family_name: string
  given_name: string
  groups: string[]
  name: string
  title: string
}
