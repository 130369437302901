import { AirportInformation } from '../models'

let dollarUS = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const toDollarUSFormat = (num: number) => {
  return dollarUS.format(num)
}

/**
 * Returns a date in string format. Ex: "14 Jun 2017"
 * @param date take a string date in format yyyy-mm-dd
 * @returns string
 */
export const toDateString = (givenDate: string) => {
  if (!givenDate.includes('T')) {
    givenDate = givenDate + 'T00:00:00'
  }
  const date = new Date(givenDate)
  return date.toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })
}

export const toTimeString = (datetimeString: string): string | null => {
  if (!datetimeString) {
    return null
  }

  const dateTimeNoOffset = datetimeString.substring(0, 16) // remove offset so that it doesn't add or subtract offset minutes; parse a host machine's timezone
  const datetime = new Date(dateTimeNoOffset)

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  return new Intl.DateTimeFormat('en-US', options).format(datetime)
}

export const toAlaskaFlightNumber = (flightNumber: string) => {
  return `AS ${flightNumber}`
}

export const toAirportString = (airportInfo: AirportInformation): string => {
  if (!airportInfo.itmName || !airportInfo.region) {
    return airportInfo.code
  }
  return `${airportInfo.itmName}, ${airportInfo.region} (${airportInfo.code})`
}

export const formatMinutes = (totalMinutes?: number): string => {
  if (!totalMinutes) {
    return '-'
  }

  const prefix = 'D' + (totalMinutes >= 0 ? '+' : '-')
  const minutesInDay = 24 * 60
  const minutesInHour = 60

  const absMinutes = Math.abs(totalMinutes)
  const days = Math.floor(absMinutes / minutesInDay)
  const remainingMinutes = absMinutes % minutesInDay
  const hours = Math.floor(remainingMinutes / minutesInHour)
  const minutes = remainingMinutes % minutesInHour

  return `${prefix}${days}d:${hours}h:${minutes}m`
}
