import React, { useEffect, useState } from 'react'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { ErrorPage, OrderStatusPill, ResultsTable } from '../../../../common'
import { OrderSearchParams, orderService } from '../../../../../services'
import { FlightInformation, OrderSearchResponse, RestError } from '../../../../../models'
import { useNavigate } from 'react-router-dom'
import { formatPassenger, renderPassengerCell } from '../../../Orders'
import './FlightOrdersTable.scss'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Order number',
    flex: 1.5,
  },
  {
    field: 'status',
    headerName: 'Order status',
    flex: 1.5,
    renderCell: (params) => <OrderStatusPill status={params.value} />,
  },
  {
    field: 'type',
    headerName: 'Order type',
    flex: 0.8,
  },
  {
    field: 'displayCabin',
    minWidth: 150,
    headerName: 'Cabin',
  },
  {
    field: 'numberOfProductsOrdered',
    headerName: 'Items ordered',
    flex: 0.8,
  },
  {
    field: 'passengers',
    headerName: 'Guests',
    flex: 0.8,
    valueFormatter: (params) => {
      return formatPassenger(params.value)
    },
    renderCell: (params) => {
      return renderPassengerCell(params.value)
    },
  },
]

interface FlightOrdersTableProps {
  exportFilename?: string
  flightInfo: FlightInformation
  setTabOption: (option: string) => void
}

interface FlightProductsTableState {
  data?: OrderSearchResponse[]
  errorCode?: number
}

export const FlightOrdersTable = (props: FlightOrdersTableProps) => {
  const navigate = useNavigate()
  const [state, setState] = useState<FlightProductsTableState>({})

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      setState({ ...state, errorCode: undefined })
      const params: OrderSearchParams = {
        flightNumber: props.flightInfo.operatingFlightNumber,
        departureDate: props.flightInfo.departureDate,
        arrivalCity: props.flightInfo.arrivalAirportCode,
        departureCity: props.flightInfo.departureAirportCode,
      }
      const orders = await orderService.search(params)
      const activeOrders = orders.filter((order) => order.status !== 'Cancelled')
      props.setTabOption(`Orders (${activeOrders.length})`)
      setState({ ...state, data: orders })
    } catch (error) {
      setState({ ...state, errorCode: (error as RestError).statusCode ?? 500 })
    }
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`order/${params.id}/${params.row.partitionKey}`)
  }

  if (state.errorCode) {
    return <ErrorPage errorCode={state.errorCode} errorNotFoundMsg={'Orders not found.'} handleRetryClick={fetchData} />
  }

  if (!state.data) {
    return <auro-skeleton shape="rectangle" class={'skeleton-table'} />
  }

  return (
    <div className="resultsContainer">
      <ResultsTable
        exportFilename={props.exportFilename}
        hideHeaderText={true}
        columns={columns}
        handleRowClick={handleRowClick}
        items={state.data}
      />
    </div>
  )
}
