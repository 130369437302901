import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GridRowParams } from '@mui/x-data-grid'
import { orderService } from '../../../../services'
import { Guest, OrderSearchResponse } from '../../../../models'
import { ErrorPage, InfoBox, ResultsTable } from '../../../common'
import { RootState, setBreadcrumbs } from '../../../../store'
import { getGuestDetailsBreadCrumbs } from '../../../../utils'
import { useFetchState } from '../../../../hooks'
import './GuestDetails.scss'
import { orderResultsColumns } from '../../Orders/OrderResults/OrderResults'

export const GuestDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loyaltyNumber, lastName, firstName, id } = useParams()
  const { guests } = useSelector((rootState: RootState) => rootState)
  const fetchData = async () => await orderService.search({ loyaltyNumber: loyaltyNumber, id })
  const { fetchState, retryFetchData } = useFetchState<OrderSearchResponse[]>(fetchData)

  useEffect(() => {
    if (!guests.selectedGuest) {
      navigate('/guests')
    }
    dispatch(setBreadcrumbs(getGuestDetailsBreadCrumbs(loyaltyNumber as string, lastName as string, firstName as string, id as string)))
    fetchData()
  }, [])

  const handleRowClick = (params: GridRowParams) => {
    navigate(`order/${params.row.id}/${params.row.partitionKey}`)
  }

  const renderInfoBoxes = (guest: Guest) => {
    return (
      <div className="info-boxes">
        <InfoBox iconName="account-stroke" iconCategory="interface" header="Mileage plan #" info={guest.loyaltyNumber ?? 'N/A'} />
        <InfoBox iconName="mail" iconCategory="interface" header="Email" info={'N/A'} />
      </div>
    )
  }

  if (fetchState.errorCode) {
    return (
      <ErrorPage errorCode={fetchState.errorCode} errorNotFoundMsg={'Unable to retrieve orders for this guest.'} handleRetryClick={retryFetchData} />
    )
  }

  return (
    <div className="guest-details-container">
      {guests?.selectedGuest && (
        <div className="main-header-container">
          <div className="page-title-container">
            <auro-header margin="both" size="none" level="1" display="600">
              {guests.selectedGuest.fullName}
            </auro-header>
          </div>
          {renderInfoBoxes(guests.selectedGuest)}
        </div>
      )}
      <div className="guestOrdersContainer">
        <auro-header display="400" margin="both" size="none">
          Order History
        </auro-header>
        {fetchState.data && (
          <ResultsTable
            exportFilename={`${guests.selectedGuest?.fullName}-orders`}
            hideHeaderText={true}
            columns={orderResultsColumns}
            handleRowClick={handleRowClick}
            items={fetchState.data}
          />
        )}
      </div>
    </div>
  )
}
