export interface Product {
  allergyInfo: string
  cabinCode?: string
  category: string
  code: string
  description: string
  id: string
  images: string[]
  isActive: boolean
  name: string
  nutritionInfo: string
  partitionKey: string
  startTimeOffset?: number
  endTimeOffset?: number
  cancelEndTimeOffset?: number
  prices: Price[]
  _ts: number // CosmosDB timestamp. When we're the source of truth we can supply this ourselves, something like dateAdded
}

export interface Price {
  price: number
  startDate?: string
  endDate?: string
}
