import { NodeCrypto, NodeRequestor } from '@openid/appauth/built/node_support'
import {
  AuthorizationNotifier,
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  TokenResponse,
  RedirectRequestHandler,
  BaseTokenRequestHandler,
  TokenRequest,
  StringMap,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  BasicQueryStringUtils,
  LocalStorageBackend,
  LocationLike,
  DefaultCrypto,
  AuthorizationResponse,
} from '@openid/appauth'
import { UserInfo } from '../../models/userInfo'
import { setAccessToken, store } from '../../store'

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike, _useHash?: boolean): StringMap {
    return super.parse(input, false /* never use hash */)
  }
}

class AuthService {
  private OIDC_URL = process.env.OIDC_URL ?? ''
  private CLIENT_ID = process.env.CLIENT_ID ?? ''
  private REDIRECT_URI = window.location.origin + '/signin/callback'
  private SCOPE = 'openid profile email'

  private requestor = new NodeRequestor()
  private authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  )
  private notifier = new AuthorizationNotifier()
  private tokenHandler = new BaseTokenRequestHandler(this.requestor)
  private configuration?: AuthorizationServiceConfiguration
  private accessTokenResponse?: TokenResponse

  /**
   * Sets up authorization listener: Whenever the client signs in and makes an
   * authorization request, create and store a new refresh and access token.
   */
  constructor() {
    this.authorizationHandler.setAuthorizationNotifier(this.notifier)
    this.notifier.setAuthorizationListener(async (request, response, error) => {
      await this.authCallback(request, response, error)
    })
  }

  /**
   * Makes a new access token via refresh token, only if the refresh token is valid and
   * the old access token is invalid. Then store the tokens.
   * @param refreshToken the refresh token string.
   */
  async makeAccessToken(refreshToken: string) {
    if (!this.configuration) {
      throw new Error('Configuration is not defined')
    }

    if (this.accessTokenResponse?.isValid(0)) {
      console.log('Reusing access token')
      return this.accessTokenResponse.accessToken
    }

    try {
      console.log('Making a new access token')
      const tokenRequest = this.createNewTokenRequest(GRANT_TYPE_REFRESH_TOKEN, undefined, undefined, refreshToken)
      this.accessTokenResponse = await this.tokenHandler.performTokenRequest(this.configuration, tokenRequest)
      localStorage.setItem('refresh_token', this.accessTokenResponse.refreshToken ?? '')
      store.dispatch(setAccessToken(this.accessTokenResponse.accessToken))
      return this.accessTokenResponse.accessToken
    } catch (err) {
      this.signOut()
    }
  }

  private async authCallback(request: AuthorizationRequest, response: AuthorizationResponse | null, _error: any) {
    if (response && request.internal?.code_verifier) {
      await this.makeRefreshToken(response.code, request.internal.code_verifier)
    } else {
      throw new Error('Invalid code verified received.')
    }
  }

  /**
   * Makes a new refresh and access token via auth code, then store the tokens.
   * @param code the url code given from auth response.
   * @param codeVerifier the url code verifier given from auth response.
   * @returns the refresh token string.
   */
  private async makeRefreshToken(code: string, codeVerifier: string) {
    if (!this.configuration) {
      throw new Error('Configuration not defined')
    }
    const tokenRequest = this.createNewTokenRequest(GRANT_TYPE_AUTHORIZATION_CODE, code, codeVerifier)
    const response = await this.tokenHandler.performTokenRequest(this.configuration, tokenRequest)
    this.accessTokenResponse = response
    store.dispatch(setAccessToken(response.accessToken))
    localStorage.setItem('refresh_token', response.refreshToken ?? '')
  }

  /**
   * Creates and return a TokenRequest object based on the given parameters.
   */
  private createNewTokenRequest(grant_type: string, code?: string, codeVerifier?: string, refreshTok?: string): TokenRequest {
    const extras: StringMap = {}
    if (codeVerifier != null) {
      extras.code_verifier = codeVerifier
    }
    return new TokenRequest({
      client_id: this.CLIENT_ID,
      grant_type: grant_type,
      redirect_uri: this.REDIRECT_URI,
      code: code,
      refresh_token: refreshTok,
      extras: extras,
    })
  }

  /**
   * Fetches and stores the auth service configurations.
   */
  async fetchServiceConfig() {
    if (this.configuration) {
      return
    }
    this.configuration = await AuthorizationServiceConfiguration.fetchFromIssuer(this.OIDC_URL, this.requestor)
  }

  /**
   * Create and perform a new authorization request.
   */
  async signIn() {
    this.ThrowWhenNull(this.configuration)
    const extras: StringMap = { access_type: 'offline' }
    const authRequest = new AuthorizationRequest(
      {
        client_id: this.CLIENT_ID,
        redirect_uri: this.REDIRECT_URI,
        scope: this.SCOPE,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined,
        extras: extras,
      },
      new NodeCrypto(),
    )

    this.authorizationHandler.performAuthorizationRequest(this.configuration!, authRequest)
  }

  /**
   * Handles a possible pending authorization request: Redirects the client to the
   * redirect uri and triggers the authorization listener from the constructor.
   */
  async completeAuthorizationRequestIfPossible() {
    await this.authorizationHandler.completeAuthorizationRequestIfPossible()
  }

  /**
   * Removes all access and refresh tokens.
   */
  signOut() {
    localStorage.removeItem('refresh_token')
    this.accessTokenResponse = undefined
    window.location.href = '/signin'
  }

  private ThrowWhenNull(configuration?: AuthorizationServiceConfiguration): void {
    if (!configuration) {
      throw new Error('Service configuration has not been initialized.')
    }
  }

  /**
   * Fetches the user information via the access token.
   * @returns a string json of the user information.
   */
  async fetchUserInfo(): Promise<UserInfo> {
    const response = await fetch(`${this.OIDC_URL}/idp/userinfo.openid`, {
      headers: new Headers({
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      }),
      method: 'GET',
      cache: 'no-cache',
    })
    if (!response.ok) {
      throw new Error('Unable to get user info')
    }
    return await response.json()
  }
}

export const authService = new AuthService()
