import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { productService } from '../../../../services'
import { setBreadcrumbs, setProducts } from '../../../../store'
import { clearFields, FormFieldMap, getSearchCrumbs, populateCategories, DomainTypes } from '../../../../utils'
import { SearchForm } from '../../../common'
import './ProductSearch.scss'

export const ProductSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [categories, setCategories] = useState<string[]>([])

  useEffect(() => {
    populateCategories(setCategories)
  }, [])

  useEffect(() => {
    if (!location.pathname.includes('results')) {
      dispatch(setBreadcrumbs(getSearchCrumbs(DomainTypes.PRODUCTS)))
    }
  }, [location.pathname])

  const handleSubmit = async (searchParams: FormFieldMap) => {
    const response = await productService.search(searchParams)
    dispatch(setProducts({ results: response }))
  }

  const renderMainInputFields = () => {
    return (
      <div className="main-input-fields">
        <auro-input requestparam="name" label="Name" bordered />
        {/* todo - multi-select */}
        <auro-select requestparam="category" placeholder="Category">
          <auro-menu>
            {categories.map((c) => (
              <auro-menuoption key={c} value={c}>
                {c}
              </auro-menuoption>
            ))}
          </auro-menu>
        </auro-select>
      </div>
    )
  }

  const renderAdditionalFields = () => {
    return (
      <div className="additional-search-fields-container">
        <div className="input-field-rows">
          <auro-input requestparam="id" label="ID" bordered />
          <auro-input requestparam="code" label="Code" bordered />
        </div>
        <div className="input-field-rows">
          <auro-select requestparam="price" placeholder="Price" class="price">
            <auro-menu>
              <auro-menuoption value="1-6.99">$1-$6.99</auro-menuoption>
              <auro-menuoption value="7-">$7+</auro-menuoption>
            </auro-menu>
          </auro-select>
          <auro-input requestparam="description" label="Description" bordered />
        </div>
      </div>
    )
  }

  return (
    <SearchForm
      additionalFields={renderAdditionalFields()}
      handleClearFields={clearFields}
      handleSearchButtonClick={handleSubmit}
      headerText={'Product search'}
      mainFields={renderMainInputFields()}
    />
  )
}
