import { authService } from '..'
import { Flight, FlightSearchResponse, FlightViewModel, RestError } from '../../models'

export interface FlightSearchParams {
  arrivalAirportCode?: string
  arrivalDate?: string
  departureAirportCode?: string
  departureStartDate?: string
  departureEndDate?: string
  flightNumber?: string
}

class FlightService {
  private BASE_URL = process.env.API_BASEURL
  private PATH = process.env.API_PATH
  private KEY = process.env.API_KEY ?? ''

  async search(searchParams: FlightSearchParams): Promise<FlightSearchResponse[]> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/flights/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
      body: JSON.stringify(searchParams),
    })
    if (!response.ok) {
      throw new RestError('Error calling /flights/search', response.status, await response.text())
    }
    return response.json()
  }

  async getFlight(id: string): Promise<FlightViewModel> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/flights/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
    })
    if (!response.ok) {
      throw new RestError('Error calling /flights/:id', response.status, await response.text())
    }
    const res: Flight = await response.json()

    return {
      id: res.id,
      partitionKey: res.partitionKey,
      flightInformation: res.flightInformation,
      productInventories: Object.values(res.productInventories ?? {}),
      categoryInventories: Object.values(res.categoryInventories ?? {}),
    }
  }
}

export const flightService = new FlightService()
