import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Price, Product } from '../../../../models/product'
import { productService } from '../../../../services'
import { InfoBox, ErrorPage } from '../../../common'
import { getDetailsCrumbs, toDollarUSFormat, DomainTypes, getFlightProductBreadCrumbs, formatMinutes } from '../../../../utils'
import { setBreadcrumbs } from '../../../../store'
import { useFetchState } from '../../../../hooks'
import { ProductSkeleton } from './ProductSkeleton'
import './ProductDetails.scss'

export const ProductDetails = () => {
  const dispatch = useDispatch()
  const { id, flightId } = useParams()
  const fetchData = async () => await productService.getProduct(id as string)
  const { fetchState, retryFetchData } = useFetchState<Product>(fetchData)

  useEffect(() => {
    if (!fetchState.data) {
      return
    }
    if (flightId) {
      dispatch(setBreadcrumbs(getFlightProductBreadCrumbs(flightId, id as string, fetchState.data.name)))
    } else {
      dispatch(setBreadcrumbs(getDetailsCrumbs(DomainTypes.PRODUCTS, { id: fetchState.data.id, displayName: fetchState.data.name })))
    }
  }, [fetchState.data])

  const getCurrentPrice = (prices: Price[]): number => {
    const result = prices.find((price: Price) => {
      const now = Date.now()
      const from = new Date(price.startDate ?? now)
      const to = new Date(price.endDate ?? now)
      return from.getTime() <= now && now <= to.getTime()
    })

    return result?.price ?? 0
  }

  if (fetchState.errorCode) {
    return <ErrorPage errorCode={fetchState.errorCode} errorNotFoundMsg={'Product not found.'} handleRetryClick={retryFetchData} />
  }

  if (fetchState.data == null) {
    return <ProductSkeleton />
  }

  return (
    <div className="product-details-container">
      <div className="top">
        <div className="auro-carousel-placeholder">
          {fetchState.data.images && fetchState.data.images.length > 0 ? (
            <img alt={fetchState.data.name} src={fetchState.data.images[0]} />
          ) : (
            <div>
              <auro-icon name="food-and-drink" category="in-flight" customSize />
            </div>
          )}
        </div>
        <div className="details">
          <div className="header">
            <auro-header margin="both" size="none" level="1" display="600">
              {fetchState.data.name}
            </auro-header>
            <auro-badge success={fetchState.data.isActive ? true : undefined}>{fetchState.data.isActive ? 'Active' : 'Inactive'}</auro-badge>
          </div>
          <div className="product-sub-header-container">
            <div>
              <h2>ID</h2>
              <h2>Code</h2>
            </div>
            <div>
              <h2>{`${fetchState.data.id}`}</h2>
              <h2>{fetchState.data.code}</h2>
            </div>
          </div>
          <div className="details-table">
            <InfoBox
              iconName="seat"
              iconCategory="in-flight"
              header="Cabin"
              info={fetchState.data.cabinCode === 'Y' ? 'Main Cabin' : 'First Class'}
            />
            <InfoBox iconName="food-and-drink" iconCategory="in-flight" header="Category" info={fetchState.data.category} />
            <InfoBox
              iconName="contract-fare"
              iconCategory="interface"
              header="Price"
              info={`${toDollarUSFormat(getCurrentPrice(fetchState.data.prices))} USD`}
            />
            {fetchState.data.startTimeOffset && <InfoBox header="Pre-order Begins" info={formatMinutes(fetchState.data.startTimeOffset)} />}
            {fetchState.data.endTimeOffset && <InfoBox header="Pre-order Cutoff" info={formatMinutes(fetchState.data.endTimeOffset)} />}
            {fetchState.data.cancelEndTimeOffset && (
              <InfoBox header="Pre-order Cancel Cutoff" info={formatMinutes(fetchState.data.cancelEndTimeOffset)} />
            )}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="bottom-title-container">
          <auro-header display="400" margin="both" size="none">
            Product details
          </auro-header>
        </div>
        <div className="bottom-content-container">
          <p>Description</p>
          <p>{fetchState.data.description ?? 'No description available'}</p>
          <p>Nutrition</p>
          <p>{fetchState.data.nutritionInfo ?? 'No nutrition information available'}</p>
          <p>Contains/other</p>
          <p>{fetchState.data.allergyInfo ?? 'No allergy information available'}</p>
          <p>Date imported</p>
          <p>{new Date(fetchState.data._ts * 1000).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  )
}
