import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { orderService } from '../../../../../services'
import { setBreadcrumbs, setOrders } from '../../../../../store'
import { clearFields, FormFieldMap, getSearchCrumbs, DomainTypes, setButtonState } from '../../../../../utils'
import { SearchForm } from '../../../../common'
import { AdditionalOrderFields } from '../AdditionalOrderFields/AdditionalOrderFields'
import './OrderSearch.scss'

export const OrderSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isDisabled, setDisabled] = React.useState(true)

  useEffect(() => {
    if (!location.pathname.includes('results')) {
      dispatch(setBreadcrumbs(getSearchCrumbs(DomainTypes.ORDERS)))
    }
  }, [location.pathname])

  const handleClearFields = () => {
    clearFields()
    setButtonState(setDisabled)
  }

  const handleSubmit = async (searchParams: FormFieldMap) => {
    const response = await orderService.search(searchParams)

    dispatch(setOrders({ results: response }))
  }

  const renderMainInputFields = () => {
    return (
      <div className="main-input-fields">
        <auro-input
          bordered
          validateOnInput
          setCustomValidity="Order number must be in the format of flight number, departure airport code, arrival airport code, departure date, and confirmation code, separated with dashes."
          maxLength="30"
          requestparam="id"
          label="Order number"
          pattern="(^$|^[0-9]{1,4}-[a-zA-Z]{3}-[a-zA-Z]{3}-[0-9]{4}-[0-9]{2}-[0-9]{2}-[a-zA-Z]{6}$)"
        >
          <span slot="helptext">
            This can be the x-digit onboard order number or the 23 digit pre-order identifier (e.g. 122-SEA-FLL-2022-07-08-UXUAYR)
          </span>
        </auro-input>
      </div>
    )
  }

  return (
    <SearchForm
      headerText="Order search"
      mainFields={renderMainInputFields()}
      additionalFields={<AdditionalOrderFields />}
      handleClearFields={handleClearFields}
      handleSearchButtonClick={handleSubmit}
      handleInput={setButtonState.bind(this, setDisabled)}
      shouldDisableSearchButton={isDisabled}
    />
  )
}
