import { TimeframeOption } from '../models'
import { getInvalidAuroInputs, getValidAuroInputs, getNonEmptyAuroSelects } from './searchPageUtils'

export const setButtonState = (setDisabled: (isDisabled: boolean) => void): void => {
  setDisabled(isSearchEmptyOrInvalid())
}

export const setFlightSearchButtonState = (setDisabled: (isDisabled: boolean) => void): void => {
  const isSearchInvalid = isSearchEmptyOrInvalid()
  const timeframe = document.querySelector('.selected') as HTMLInputElement

  if (timeframe?.textContent === TimeframeOption.Between && !isSearchInvalid) {
    const departureStartDate = document.getElementById('departureStartDate') as HTMLInputElement
    const departureEndDate = document.getElementById('departureEndDate') as HTMLInputElement

    if (!!departureStartDate.value && !!departureEndDate.value) {
      setDisabled(departureStartDate.value > departureEndDate.value)
    } else if (!departureStartDate.value && !departureEndDate.value) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  } else {
    setDisabled(isSearchInvalid)
  }
}

const isSearchEmptyOrInvalid = () => {
  const validInputs = getValidAuroInputs()
  const validSelects = getNonEmptyAuroSelects()
  const invalidInputs = getInvalidAuroInputs()
  return validInputs.length + validSelects.length === 0 || invalidInputs.length > 0
}
