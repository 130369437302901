import React from 'react'
import './Signin.scss'
import { authService } from '../../../services'

export const SigninPage = () => {
  const [isFetching, setIsFetching] = React.useState(false)

  const handleSignIn = async () => {
    setIsFetching(true)
    try {
      await authService.fetchServiceConfig()
      await authService.signIn()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <section className="signin-page-container">
      <div className="signin-content-container">
        <auro-lockup>
          <span slot="title">ASTRO</span>
          <span slot="subtitle">Alaska Transactions and Orders Management System</span>
        </auro-lockup>
        {isFetching ? (
          <auro-loader onlight={true} orbit={true} md={true} />
        ) : (
          <auro-button className="signin-button" onClick={handleSignIn}>
            Sign In
          </auro-button>
        )}
      </div>
    </section>
  )
}
