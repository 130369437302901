import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { flightService } from '../../../../../services'
import { setBreadcrumbs, setFlights } from '../../../../../store'
import { getSearchCrumbs, DomainTypes, setFlightSearchButtonState, clearFields, FormFieldMap, normalizeAtTimeframe } from '../../../../../utils'
import { SearchForm } from '../../../../common'
import { AdditionalFlightFields } from '..'
import { useLocation } from 'react-router-dom'

export const FlightSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [isInputsEmpty, setIsInputsEmpty] = React.useState(true)

  useEffect(() => {
    if (!location.pathname.includes('results')) {
      dispatch(setBreadcrumbs(getSearchCrumbs(DomainTypes.FLIGHTS)))
    }
  }, [location.pathname])

  const handleClearFields = () => {
    clearFields()
  }

  const handleSubmit = async (searchParams: FormFieldMap) => {
    searchParams = normalizeAtTimeframe(searchParams)
    const response = await flightService.search(searchParams)
    dispatch(setFlights({ results: response }))
  }

  const renderMainInputFields = () => {
    return (
      <div className="main-input-fields">
        <auro-input bordered validateOnInput type="number" maxLength="4" requestparam="flightNumber">
          <slot slot="label">Flight number</slot>
          <span slot="helptext">May only contain numbers.</span>
        </auro-input>
      </div>
    )
  }

  return (
    <SearchForm
      headerText="Flights search"
      mainFields={renderMainInputFields()}
      additionalFields={AdditionalFlightFields()}
      handleClearFields={handleClearFields}
      handleSearchButtonClick={handleSubmit}
      handleInput={setFlightSearchButtonState.bind(this, setIsInputsEmpty)}
      shouldDisableSearchButton={isInputsEmpty}
    />
  )
}
