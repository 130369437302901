import _extends from "@babel/runtime/helpers/esm/extends";
import { GridLogicOperator } from '../../../models';
import { GLOBAL_API_REF, isInternalFilter } from '../../../colDef/utils';
import { getDefaultGridFilterModel } from './gridFilterState';
import { buildWarning } from '../../../utils/warning';
import { gridColumnFieldsSelector, gridColumnLookupSelector, gridVisibleColumnFieldsSelector } from '../columns';

// Fixes https://github.com/mui/mui-x/issues/10056
const globalScope = typeof window === 'undefined' ? globalThis : window;
const evalCode = globalScope[atob('ZXZhbA==')];
let hasEval;
try {
  hasEval = evalCode('true');
} catch (_) {
  hasEval = false;
}
/**
 * Adds default values to the optional fields of a filter items.
 * @param {GridFilterItem} item The raw filter item.
 * @param {React.MutableRefObject<GridApiCommunity>} apiRef The API of the grid.
 * @return {GridFilterItem} The clean filter item with an uniq ID and an always-defined operator.
 * TODO: Make the typing reflect the different between GridFilterInputItem and GridFilterItem.
 */
export const cleanFilterItem = (item, apiRef) => {
  const cleanItem = _extends({}, item);
  if (cleanItem.id == null) {
    cleanItem.id = Math.round(Math.random() * 1e5);
  }
  if (cleanItem.operator == null) {
    // Selects a default operator
    // We don't use `apiRef.current.getColumn` because it is not ready during state initialization
    const column = gridColumnLookupSelector(apiRef)[cleanItem.field];
    cleanItem.operator = column && column.filterOperators[0].value;
  }
  return cleanItem;
};
const filterModelDisableMultiColumnsFilteringWarning = buildWarning(['MUI: The `filterModel` can only contain a single item when the `disableMultipleColumnsFiltering` prop is set to `true`.', 'If you are using the community version of the `DataGrid`, this prop is always `true`.'], 'error');
const filterModelMissingItemIdWarning = buildWarning('MUI: The `id` field is required on `filterModel.items` when you use multiple filters.', 'error');
const filterModelMissingItemOperatorWarning = buildWarning('MUI: The `operator` field is required on `filterModel.items`, one or more of your filtering item has no `operator` provided.', 'error');
export const sanitizeFilterModel = (model, disableMultipleColumnsFiltering, apiRef) => {
  const hasSeveralItems = model.items.length > 1;
  let items;
  if (hasSeveralItems && disableMultipleColumnsFiltering) {
    filterModelDisableMultiColumnsFilteringWarning();
    items = [model.items[0]];
  } else {
    items = model.items;
  }
  const hasItemsWithoutIds = hasSeveralItems && items.some(item => item.id == null);
  const hasItemWithoutOperator = items.some(item => item.operator == null);
  if (hasItemsWithoutIds) {
    filterModelMissingItemIdWarning();
  }
  if (hasItemWithoutOperator) {
    filterModelMissingItemOperatorWarning();
  }
  if (hasItemWithoutOperator || hasItemsWithoutIds) {
    return _extends({}, model, {
      items: items.map(item => cleanFilterItem(item, apiRef))
    });
  }
  if (model.items !== items) {
    return _extends({}, model, {
      items
    });
  }
  return model;
};
export const mergeStateWithFilterModel = (filterModel, disableMultipleColumnsFiltering, apiRef) => filteringState => _extends({}, filteringState, {
  filterModel: sanitizeFilterModel(filterModel, disableMultipleColumnsFiltering, apiRef)
});
const getFilterCallbackFromItem = (filterItem, apiRef) => {
  if (!filterItem.field || !filterItem.operator) {
    return null;
  }
  const column = apiRef.current.getColumn(filterItem.field);
  if (!column) {
    return null;
  }
  let parsedValue;
  if (column.valueParser) {
    var _filterItem$value;
    const parser = column.valueParser;
    parsedValue = Array.isArray(filterItem.value) ? (_filterItem$value = filterItem.value) == null ? void 0 : _filterItem$value.map(x => parser(x)) : parser(filterItem.value);
  } else {
    parsedValue = filterItem.value;
  }
  const newFilterItem = _extends({}, filterItem, {
    value: parsedValue
  });
  const filterOperators = column.filterOperators;
  if (!(filterOperators != null && filterOperators.length)) {
    throw new Error(`MUI: No filter operators found for column '${column.field}'.`);
  }
  const filterOperator = filterOperators.find(operator => operator.value === newFilterItem.operator);
  if (!filterOperator) {
    throw new Error(`MUI: No filter operator found for column '${column.field}' and operator value '${newFilterItem.operator}'.`);
  }
  const hasUserFunctionLegacy = !isInternalFilter(filterOperator.getApplyFilterFn);
  const hasUserFunctionV7 = !isInternalFilter(filterOperator.getApplyFilterFnV7);
  if (filterOperator.getApplyFilterFnV7 && !(hasUserFunctionLegacy && !hasUserFunctionV7)) {
    const applyFilterOnRow = filterOperator.getApplyFilterFnV7(newFilterItem, column);
    if (typeof applyFilterOnRow !== 'function') {
      return null;
    }
    return {
      v7: true,
      item: newFilterItem,
      fn: row => {
        const value = apiRef.current.getRowValue(row, column);
        return applyFilterOnRow(value, row, column, apiRef);
      }
    };
  }
  const applyFilterOnRow = filterOperator.getApplyFilterFn(newFilterItem, column);
  if (typeof applyFilterOnRow !== 'function') {
    return null;
  }
  return {
    v7: false,
    item: newFilterItem,
    fn: rowId => {
      const params = apiRef.current.getCellParams(rowId, newFilterItem.field);
      GLOBAL_API_REF.current = apiRef;
      const result = applyFilterOnRow(params);
      GLOBAL_API_REF.current = null;
      return result;
    }
  };
};
let filterItemsApplierId = 1;

/**
 * Generates a method to easily check if a row is matching the current filter model.
 * @param {GridRowIdGetter | undefined} getRowId The getter for row's id.
 * @param {GridFilterModel} filterModel The model with which we want to filter the rows.
 * @param {React.MutableRefObject<GridApiCommunity>} apiRef The API of the grid.
 * @returns {GridAggregatedFilterItemApplier | null} A method that checks if a row is matching the current filter model. If `null`, we consider that all the rows are matching the filters.
 */
export const buildAggregatedFilterItemsApplier = (getRowId, filterModel, apiRef, disableEval) => {
  const {
    items
  } = filterModel;
  const appliers = items.map(item => getFilterCallbackFromItem(item, apiRef)).filter(callback => !!callback);
  if (appliers.length === 0) {
    return null;
  }
  if (!hasEval || disableEval) {
    // This is the original logic, which is used if `eval()` is not supported (aka prevented by CSP).
    return (row, shouldApplyFilter) => {
      const resultPerItemId = {};
      for (let i = 0; i < appliers.length; i += 1) {
        const applier = appliers[i];
        if (!shouldApplyFilter || shouldApplyFilter(applier.item.field)) {
          resultPerItemId[applier.item.id] = applier.v7 ? applier.fn(row) : applier.fn(getRowId ? getRowId(row) : row.id);
        }
      }
      return resultPerItemId;
    };
  }

  // We generate a new function with `eval()` to avoid expensive patterns for JS engines
  // such as a dynamic object assignment, e.g. `{ [dynamicKey]: value }`.
  const filterItemTemplate = `(function filterItem$$(appliers, row, shouldApplyFilter) {
      ${appliers.map((applier, i) => `const shouldApply${i} = !shouldApplyFilter || shouldApplyFilter(${JSON.stringify(applier.item.field)});`).join('\n')}

      const result$$ = {
      ${appliers.map((applier, i) => `${JSON.stringify(String(applier.item.id))}:
          !shouldApply${i} ?
            false :
            ${applier.v7 ? `appliers[${i}].fn(row)` : `appliers[${i}].fn(${getRowId ? 'getRowId(row)' : 'row.id'})`},
      `).join('\n')}};

      return result$$;
    })`;
  const filterItemCore = evalCode(filterItemTemplate.replaceAll('$$', String(filterItemsApplierId)));
  const filterItem = (row, shouldApplyItem) => {
    return filterItemCore(appliers, row, shouldApplyItem);
  };
  filterItemsApplierId += 1;
  return filterItem;
};

/**
 * Generates a method to easily check if a row is matching the current quick filter.
 * @param {GridRowIdGetter | undefined} getRowId The getter for row's id.
 * @param {any[]} filterModel The model with which we want to filter the rows.
 * @param {React.MutableRefObject<GridApiCommunity>} apiRef The API of the grid.
 * @returns {GridAggregatedFilterItemApplier | null} A method that checks if a row is matching the current filter model. If `null`, we consider that all the rows are matching the filters.
 */
export const buildAggregatedQuickFilterApplier = (getRowId, filterModel, apiRef) => {
  var _filterModel$quickFil, _filterModel$quickFil2, _filterModel$quickFil3;
  const quickFilterValues = (_filterModel$quickFil = (_filterModel$quickFil2 = filterModel.quickFilterValues) == null ? void 0 : _filterModel$quickFil2.filter(Boolean)) != null ? _filterModel$quickFil : [];
  if (quickFilterValues.length === 0) {
    return null;
  }
  const quickFilterExcludeHiddenColumns = (_filterModel$quickFil3 = filterModel.quickFilterExcludeHiddenColumns) != null ? _filterModel$quickFil3 : false;
  const columnFields = quickFilterExcludeHiddenColumns ? gridVisibleColumnFieldsSelector(apiRef) : gridColumnFieldsSelector(apiRef);
  const appliersPerField = [];
  columnFields.forEach(field => {
    const column = apiRef.current.getColumn(field);
    const getApplyQuickFilterFn = column == null ? void 0 : column.getApplyQuickFilterFn;
    const getApplyQuickFilterFnV7 = column == null ? void 0 : column.getApplyQuickFilterFnV7;
    const hasUserFunctionLegacy = !isInternalFilter(getApplyQuickFilterFn);
    const hasUserFunctionV7 = !isInternalFilter(getApplyQuickFilterFnV7);
    if (getApplyQuickFilterFnV7 && !(hasUserFunctionLegacy && !hasUserFunctionV7)) {
      appliersPerField.push({
        column,
        appliers: quickFilterValues.map(value => ({
          v7: true,
          fn: getApplyQuickFilterFnV7(value, column, apiRef)
        }))
      });
    } else if (getApplyQuickFilterFn) {
      appliersPerField.push({
        column,
        appliers: quickFilterValues.map(value => ({
          v7: false,
          fn: getApplyQuickFilterFn(value, column, apiRef)
        }))
      });
    }
  });
  return function isRowMatchingQuickFilter(row, shouldApplyFilter) {
    const result = {};
    const usedCellParams = {};

    /* eslint-disable no-restricted-syntax, no-labels, no-continue */
    outer: for (let v = 0; v < quickFilterValues.length; v += 1) {
      const filterValue = quickFilterValues[v];
      for (let i = 0; i < appliersPerField.length; i += 1) {
        const {
          column,
          appliers
        } = appliersPerField[i];
        const {
          field
        } = column;
        if (shouldApplyFilter && !shouldApplyFilter(field)) {
          continue;
        }
        const applier = appliers[v];
        const value = apiRef.current.getRowValue(row, column);
        if (applier.fn === null) {
          continue;
        }
        if (applier.v7) {
          const isMatching = applier.fn(value, row, column, apiRef);
          if (isMatching) {
            result[filterValue] = true;
            continue outer;
          }
        } else {
          var _usedCellParams$field;
          const cellParams = (_usedCellParams$field = usedCellParams[field]) != null ? _usedCellParams$field : apiRef.current.getCellParams(getRowId ? getRowId(row) : row.id, field);
          usedCellParams[field] = cellParams;
          const isMatching = applier.fn(cellParams);
          if (isMatching) {
            result[filterValue] = true;
            continue outer;
          }
        }
      }
      result[filterValue] = false;
    }
    /* eslint-enable no-restricted-syntax, no-labels, no-continue */

    return result;
  };
};
export const buildAggregatedFilterApplier = (getRowId, filterModel, apiRef, disableEval) => {
  const isRowMatchingFilterItems = buildAggregatedFilterItemsApplier(getRowId, filterModel, apiRef, disableEval);
  const isRowMatchingQuickFilter = buildAggregatedQuickFilterApplier(getRowId, filterModel, apiRef);
  return function isRowMatchingFilters(row, shouldApplyFilter, result) {
    var _isRowMatchingFilterI, _isRowMatchingQuickFi;
    result.passingFilterItems = (_isRowMatchingFilterI = isRowMatchingFilterItems == null ? void 0 : isRowMatchingFilterItems(row, shouldApplyFilter)) != null ? _isRowMatchingFilterI : null;
    result.passingQuickFilterValues = (_isRowMatchingQuickFi = isRowMatchingQuickFilter == null ? void 0 : isRowMatchingQuickFilter(row, shouldApplyFilter)) != null ? _isRowMatchingQuickFi : null;
  };
};
const isNotNull = result => result != null;
const filterModelItems = (cache, apiRef, items) => {
  if (!cache.cleanedFilterItems) {
    cache.cleanedFilterItems = items.filter(item => getFilterCallbackFromItem(item, apiRef) !== null);
  }
  return cache.cleanedFilterItems;
};
export const passFilterLogic = (allFilterItemResults, allQuickFilterResults, filterModel, apiRef, cache) => {
  const cleanedFilterItems = filterModelItems(cache, apiRef, filterModel.items);
  const cleanedFilterItemResults = allFilterItemResults.filter(isNotNull);
  const cleanedQuickFilterResults = allQuickFilterResults.filter(isNotNull);

  // get result for filter items model
  if (cleanedFilterItemResults.length > 0) {
    var _filterModel$logicOpe;
    // Return true if the item pass with one of the rows
    const filterItemPredicate = item => {
      return cleanedFilterItemResults.some(filterItemResult => filterItemResult[item.id]);
    };
    const logicOperator = (_filterModel$logicOpe = filterModel.logicOperator) != null ? _filterModel$logicOpe : getDefaultGridFilterModel().logicOperator;
    if (logicOperator === GridLogicOperator.And) {
      const passesAllFilters = cleanedFilterItems.every(filterItemPredicate);
      if (!passesAllFilters) {
        return false;
      }
    } else {
      const passesSomeFilters = cleanedFilterItems.some(filterItemPredicate);
      if (!passesSomeFilters) {
        return false;
      }
    }
  }

  // get result for quick filter model
  if (cleanedQuickFilterResults.length > 0 && filterModel.quickFilterValues != null) {
    var _filterModel$quickFil4;
    // Return true if the item pass with one of the rows
    const quickFilterValuePredicate = value => {
      return cleanedQuickFilterResults.some(quickFilterValueResult => quickFilterValueResult[value]);
    };
    const quickFilterLogicOperator = (_filterModel$quickFil4 = filterModel.quickFilterLogicOperator) != null ? _filterModel$quickFil4 : getDefaultGridFilterModel().quickFilterLogicOperator;
    if (quickFilterLogicOperator === GridLogicOperator.And) {
      const passesAllQuickFilterValues = filterModel.quickFilterValues.every(quickFilterValuePredicate);
      if (!passesAllQuickFilterValues) {
        return false;
      }
    } else {
      const passesSomeQuickFilterValues = filterModel.quickFilterValues.some(quickFilterValuePredicate);
      if (!passesSomeQuickFilterValues) {
        return false;
      }
    }
  }
  return true;
};