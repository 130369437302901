import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setBreadcrumbs } from '../../../../store'
import { getResultsCrumbs, DomainTypes, toDateString, toAlaskaFlightNumber } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { ResultsTable } from '../../../common'
import { AirportInformation } from '../../../../models'

export const flightResultsColumns: GridColDef[] = [
  {
    field: 'operatingFlightNumber',
    headerName: 'Flight number',
    flex: 0.4,
    renderCell: (params) => toAlaskaFlightNumber(params.value),
  },
  {
    field: 'departureDate',
    headerName: 'Departure date',
    flex: 0.45,
    renderCell: (params) => toDateString(params.value),
  },
  {
    field: 'departureAirportInfo',
    headerName: 'Departure',
    flex: 0.5,
    renderCell: (params) => renderAirportText(params.value),
  },
  {
    field: 'arrivalAirportInfo',
    headerName: 'Arrival',
    flex: 0.5,
    renderCell: (params) => renderAirportText(params.value),
  },
]

const renderAirportText = (airportInfo: AirportInformation) => {
  if (!airportInfo.itmName || !airportInfo.region) {
    return <span>{airportInfo.code}</span>
  }
  return (
    <>
      <span>{`${airportInfo.itmName}, ${airportInfo.region}`}</span>
      <span style={{ color: 'var(--auro-color-text-secondary-on-light)' }}>&nbsp;{`(${airportInfo.code})`}</span>
    </>
  )
}

export const FlightResults = () => {
  const { flights } = useSelector((state: RootState) => state)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs(getResultsCrumbs(DomainTypes.FLIGHTS)))
  }, [])

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/flights/results/${params.id}`, {
      state: `AS #${params.row.flightNumber}`,
    })
  }

  return flights.results ? (
    <div className="resultsContainer">
      <ResultsTable exportFilename="flight-search-results" columns={flightResultsColumns} handleRowClick={handleRowClick} items={flights.results} />
    </div>
  ) : (
    <></>
  )
}
