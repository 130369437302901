/*
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* Global flags that control the behavior of App Auth JS. */
export const Flags =
    {
      /* Logging turned on ? */
      IS_LOG: true,
      /* Profiling turned on ? */
      IS_PROFILE: false,
    }

/** Set a value for the specified global control flags.  */
export function setFlag(flag: keyof typeof Flags, value: boolean) {
  Flags[flag] = value;
}

export const {IS_LOG, IS_PROFILE} = Flags;
