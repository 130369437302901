import { authService } from '..'
import { Guest, RestError } from '../../models'

export interface GuestSearchParams {
  confirmationCode?: string
  firstName?: string
  lastName?: string
  loyaltyNumber?: string
  seatNumber?: string
  email?: string
}

class GuestService {
  private BASE_URL = process.env.API_BASEURL
  private PATH = process.env.API_PATH
  private KEY = process.env.API_KEY ?? ''

  async search(searchParams: GuestSearchParams): Promise<Guest[]> {
    const response = await fetch(`${this.BASE_URL}${this.PATH}/guests/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.KEY,
        Authorization: 'Bearer ' + (await authService.makeAccessToken(localStorage.getItem('refresh_token')!)),
      },
      body: JSON.stringify(searchParams),
    })
    if (!response.ok) {
      throw new RestError('Error calling api', response.status, await response.text())
    }
    return response.json()
  }
}

export const guestService = new GuestService()
