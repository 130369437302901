import { Order, OrderType } from '../order/order'
import { CabinCode } from '../order/OrderFlightInformation/cabinCode'
import { OrderStatus } from '../order/orderStatusInformation/orderStatus'

export const mockOrder: Order = {
  id: '068c36a4-3c14-48fe-a117-db9ac6092545',
  partitionKey: '4-SEA-DCA-2022-09-16',
  partialDataInformation: {
    hasPartialData: false,
    hasDapError: false,
    hasAccountError: false,
  },
  confirmationCode: 'VZVJYF',
  cabinCode: CabinCode.MainCabin,
  type: OrderType.Preorder,
  flightInformation: {
    operatingFlightNumber: '4',
    marketingFlightNumber: '4',
    operatingClassOfService: 'Y',
    marketingClassOfService: 'Y',
    departureDate: '2022-09-16',
    departureDateTimeLocal: '2022-09-16T08:30:00',
    departureAirportCode: 'SEA',
    arrivalDate: '2022-09-16T16:32:00',
    arrivalAirportCode: 'DCA',
    cabin: CabinCode.MainCabin,
  },
  currentStatus: {
    status: OrderStatus.Placed,
    createdBy: '',
    createdAt: new Date('2022-09-13T04:56:22.344Z'),
    createdWith: '',
  },
  statusHistory: [],
  currentOrder: {
    createdBy: '',
    createdAt: new Date('2022-09-13T04:56:22.344Z'),
    createdWith: 'preorderfoodapi',
    numberOfProductsOrdered: 2,
    originalTotalPrice: 18,
    actualTotalPrice: 8.5,
    paymentInformation: {
      singleUsePayment: null,
      cardOnFilePayment: {
        id: '3364eae4-145c-4060-a58f-ebd5171c2202',
        memberGuid: '72B0BE9C-7919-41ED-8C04-4EE0566CC44C',
        name: 'Cookie M',
        creditCardLastFour: '1111',
        creditCardType: 'BA',
      },
    },
    passengers: [
      {
        sequenceId: '1.1',
        lastName: 'MILLION',
        firstName: 'TESTER',
        accountInformation: {
          accountGuid: 'guid',
          loyaltyNumber: '300012345',
          loyaltyAirlineCode: 'AS',
          tierStatus: '0',
          tierStatusDetail: 'MP',
          lastName: 'MILLION',
          firstName: 'TESTER',
          email: null,
          loyaltyInformation: null,
        },
        seatNumber: null,
        items: [
          {
            isDelivered: false,
            id: '9e9a9090-d217-76c2-c233-59f4b862ecde',
            code: '40091',
            name: 'Fresh Start Protein Platter',
            imageThumbnail:
              'https://s3.us-west-2.amazonaws.com/asa-uat-usw2-storage-public/asa/products/Image/540x400-freshstartproteinplatter_50cc4d62-739b-4938-9a8a-6f8d185487a4.jpg',
            originalPrice: 9.5,
            actualPrice: 0,
          },
          {
            isDelivered: false,
            id: '9b6a7035-9296-fa1a-fe8b-32b1374e062c',
            code: '40089',
            name: 'Sunrise Breakfast Sandwich',
            imageThumbnail:
              'https://s3.us-west-2.amazonaws.com/asa-uat-usw2-storage-public/asa/products/Image/540x400-sunrisebreakfastsandwich_14442547-e3cf-4e4c-9b44-09c726b8e34f.jpg',
            originalPrice: 8.5,
            actualPrice: 8.5,
          },
        ],
      },
    ],
  },
  orderHistory: [],
}

export const mockDiscountedOrder: Order = {
  id: '068c36a4-3c14-48fe-a117-db9ac6092545',
  partitionKey: '4-SEA-DCA-2022-09-16',
  partialDataInformation: {
    hasPartialData: false,
    hasDapError: false,
    hasAccountError: false,
  },
  confirmationCode: 'VZVJYF',
  cabinCode: CabinCode.MainCabin,
  type: OrderType.Preorder,
  flightInformation: {
    operatingFlightNumber: '4',
    marketingFlightNumber: '4',
    operatingClassOfService: 'Y',
    marketingClassOfService: 'Y',
    departureDate: '2022-09-16',
    departureDateTimeLocal: '2022-09-16T08:30:00',
    departureAirportCode: 'SEA',
    arrivalDate: '2022-09-16T16:32:00',
    arrivalAirportCode: 'DCA',
    cabin: CabinCode.MainCabin,
  },
  currentStatus: {
    status: OrderStatus.Placed,
    createdBy: '',
    createdAt: new Date('2022-09-13T04:56:22.344Z'),
    createdWith: '',
  },
  statusHistory: [],
  currentOrder: {
    createdBy: '',
    createdAt: new Date('2022-09-13T04:56:22.344Z'),
    createdWith: 'preorderfoodapi',
    numberOfProductsOrdered: 2,
    originalTotalPrice: 18,
    actualTotalPrice: 8.5,
    paymentInformation: {
      singleUsePayment: null,
      cardOnFilePayment: {
        id: '3364eae4-145c-4060-a58f-ebd5171c2202',
        memberGuid: '72B0BE9C-7919-41ED-8C04-4EE0566CC44C',
        name: 'Cookie M',
        creditCardLastFour: '1111',
        creditCardType: 'BA',
      },
    },
    passengers: [
      {
        sequenceId: '1.1',
        lastName: 'MILLION',
        firstName: 'TESTER',
        accountInformation: {
          accountGuid: 'guid',
          loyaltyNumber: '300012345',
          loyaltyAirlineCode: 'AS',
          tierStatus: '0',
          tierStatusDetail: 'MP',
          lastName: 'MILLION',
          firstName: 'TESTER',
          email: null,
          loyaltyInformation: null,
        },
        seatNumber: null,
        items: [
          {
            isDelivered: false,
            id: '9e9a9090-d217-76c2-c233-59f4b862ecde',
            code: '40091',
            name: 'Fresh Start Protein Platter',
            imageThumbnail:
              'https://s3.us-west-2.amazonaws.com/asa-uat-usw2-storage-public/asa/products/Image/540x400-freshstartproteinplatter_50cc4d62-739b-4938-9a8a-6f8d185487a4.jpg',
            originalPrice: 9.5,
            actualPrice: 0,
            discountText: 'Complimentary for Million Milers',
          },
          {
            isDelivered: false,
            id: '9b6a7035-9296-fa1a-fe8b-32b1374e062c',
            code: '40089',
            name: 'Sunrise Breakfast Sandwich',
            imageThumbnail:
              'https://s3.us-west-2.amazonaws.com/asa-uat-usw2-storage-public/asa/products/Image/540x400-sunrisebreakfastsandwich_14442547-e3cf-4e4c-9b44-09c726b8e34f.jpg',
            originalPrice: 8.5,
            actualPrice: 8.5,
          },
        ],
      },
    ],
  },
  orderHistory: [],
}

export const mockEditedOrder: Order = {
  id: '068c36a4-3c14-48fe-a117-db9ac6092545',
  partitionKey: '4-SEA-DCA-2022-09-16',
  partialDataInformation: {
    hasPartialData: false,
    hasDapError: false,
    hasAccountError: false,
  },
  confirmationCode: 'VZVJYF',
  cabinCode: CabinCode.MainCabin,
  type: OrderType.Preorder,
  flightInformation: {
    operatingFlightNumber: '4',
    marketingFlightNumber: '4',
    operatingClassOfService: 'Y',
    marketingClassOfService: 'Y',
    departureDate: '2022-09-16',
    departureDateTimeLocal: '2022-09-16T08:30:00',
    departureAirportCode: 'SEA',
    arrivalDate: '2022-09-16T16:32:00',
    arrivalAirportCode: 'DCA',
    cabin: CabinCode.MainCabin,
  },
  currentStatus: {
    status: OrderStatus.Placed,
    createdBy: '',
    createdAt: new Date('2022-09-13T04:56:22.344Z'),
    createdWith: '',
  },
  statusHistory: [],
  currentOrder: {
    createdBy: '',
    createdAt: new Date('2022-09-13T04:56:22.344Z'),
    createdWith: 'preorderfoodapi',
    numberOfProductsOrdered: 1,
    originalTotalPrice: 9.5,
    actualTotalPrice: 0,
    paymentInformation: {
      singleUsePayment: null,
      cardOnFilePayment: {
        id: '3364eae4-145c-4060-a58f-ebd5171c2202',
        memberGuid: '72B0BE9C-7919-41ED-8C04-4EE0566CC44C',
        name: 'Cookie M',
        creditCardLastFour: '1111',
        creditCardType: 'BA',
      },
    },
    passengers: [
      {
        sequenceId: '1.1',
        lastName: 'MILLION',
        firstName: 'TESTER',
        accountInformation: {
          accountGuid: 'guid',
          loyaltyNumber: '300012345',
          loyaltyAirlineCode: 'AS',
          tierStatus: '0',
          tierStatusDetail: 'MP',
          lastName: 'MILLION',
          firstName: 'TESTER',
          email: null,
          loyaltyInformation: null,
        },
        seatNumber: null,
        items: [
          {
            isDelivered: false,
            id: '9e9a9090-d217-76c2-c233-59f4b862ecde',
            code: '40091',
            name: 'Fresh Start Protein Platter',
            imageThumbnail:
              'https://s3.us-west-2.amazonaws.com/asa-uat-usw2-storage-public/asa/products/Image/540x400-freshstartproteinplatter_50cc4d62-739b-4938-9a8a-6f8d185487a4.jpg',
            originalPrice: 9.5,
            actualPrice: 0,
          },
        ],
      },
    ],
  },
  orderHistory: [],
}
