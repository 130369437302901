import React from 'react'
import './OrderStatusPill.scss'

interface OrderStatusPillProps {
  status: string
}

export function OrderStatusPill(props: OrderStatusPillProps) {
  switch (props.status?.toUpperCase()) {
    case 'DELIVERED':
      return (
        <auro-badge label success>
          Delivered
        </auro-badge>
      )
    case 'PLACED':
      return (
        <auro-badge label class="tropical-pill">
          Placed
        </auro-badge>
      )
    case 'ACKNOWLEDGED':
      return (
        <auro-badge label class="tropical-pill">
          Acknowledged
        </auro-badge>
      )
    case 'DELIVERING':
      return (
        <auro-badge label class="tropical-pill">
          On its way
        </auro-badge>
      )
    case 'CANCELLED':
      return <auro-badge label>Canceled</auro-badge>
    case 'UNAVAILABLE':
      return (
        <auro-badge label error>
          Unavailable
        </auro-badge>
      )
    default:
      return (
        <auro-badge label advisory>
          Unknown
        </auro-badge>
      )
  }
}
