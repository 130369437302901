import { AirportInformation } from '../airportInformation'

export interface FlightInformation {
  operatingFlightNumber?: string
  marketingFlightNumber?: string
  departureDate: string
  departureDateTimeLocal: string
  departureAirportCode: string
  departureAirportInfo?: AirportInformation
  arrivalAirportCode: string
  arrivalAirportInfo?: AirportInformation
}
